import { Modal, Form, Input, Button, Row, Col } from "antd";
import "./style.scss";
import { useEffect } from "react";
import { submitUserInfo } from "../../../redux/actions/settings";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";

const ModalLocationDetail = ({ detail, open, onClose, selectedUserId }) => {
  const [form] = Form.useForm();

  const formatPhoneNumber = (phoneNumber) => {
    // Check if the phone number is a valid 10-digit number
    if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      return phoneNumber; // Return as is if it's not a valid number
    }

    // Format the number as 999-999-9999
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;
  };

  useEffect(() => {
    if (detail) {
      console.log("Detail Object:", detail);
      const leaseStartDate = detail?.lease_start_date
        ? moment(detail?.lease_start_date).format("YYYY-MM-DD")
        : "--";
      const leaseEndDate = detail?.lease_end_date
        ? moment(detail?.lease_end_date).format("YYYY-MM-DD")
        : "--";

      form.setFieldsValue({
        tenant_name: detail?.tenant_name,
        unit: detail?.unit,
        rent_amount: detail?.rent_amount,
        due_date: detail?.due_date,
        due_date: detail?.due_date,
        lease_start_date: leaseStartDate,
        lease_end_date: leaseEndDate,
        lease_type: detail?.lease_type,
        lease_source: detail?.lease_source,
        lease_co_signers: detail?.lease_co_signers,
        location_name: detail?.location_name,
        phone: detail?.phone,
        postal_code: detail?.postal_code,
        state_province: detail?.state_province,
        email: detail?.email,
        country: detail?.country,
        city: detail?.city,
        property_name: detail?.property_name,

      });
    }
  }, [detail]);

  return (
    <Modal
      wrapClassName="modalInvite modalCreateUser"
      open={open}
      onCancel={onClose}
      title=""
      footer={null}
    >
      <h3 className="title">Location Details</h3>

      <Form form={form} className="formProfile" layout="vertical">
        <Row gutter={[54, 15]}>
          <Col md={12}>
            <Form.Item label="Tenant Name" name="tenant_name">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("tenant_name")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Unit" name="unit">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("unit")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Rent Amount" name="rent_amount">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("rent_amount")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Due Date" name="due_date">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("due_date")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Lease Start Date" name="lease_start_date">
              <Input
                placeholder="Lease Start Date"
                value={form.getFieldValue("lease_start_date")}
                disabled
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label="Lease End Date" name="lease_end_date">
              <Input
                placeholder="Lease End Date"
                value={form.getFieldValue("lease_end_date")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Lease Type" name="lease_type">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("lease_type")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Lease Source" name="lease_source">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("lease_source")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Lease Cosigners" name="lease_co_signers">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("lease_co_signers")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Location Name" name="location_name">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("location_name")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Phone" name="phone">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("phone")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Postal Code" name="postal_code">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("postal_code")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="State/Province" name="state_province">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("state_province")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Email" name="email">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("email")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Country" name="country">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("country")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="City" name="city">
              <Input
                placeholder=""
                defaultValue=""
                value={form.getFieldValue("city")}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Property Name"
              name="property_name"          
            >
              <Input placeholder="" defaultValue="" value={form.getFieldValue("property_name")} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalLocationDetail;
