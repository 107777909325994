import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterInfo } from "../../../redux/actions/dashboard";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Select,
  MenuItem,
  Box,
  Button,
  TextField,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import { resetFilters } from "../../../redux/actions/dashboard";
import CocaColaIcon from "../../../assets/img/icons/cocaColaicon.svg";
import ikeaLogo from "../../../assets/img/icons/ikeaLogo.svg";
import mazdaIcon from "../../../assets/img/icons/mazdaIcon.svg";
import rvLog from "../../../assets/img/icons/rvLog.svg";
import hrLogo from "../../../assets/img/icons/hrLogo.svg";
import premium from "../../../assets/img/icons/premiumlogo.svg";
import moreRewards from "../../../assets/img/icons/moreRewards.png";
import aeroplanLogo from "../../../assets/img/icons/aeroplanLogo.svg";
import SceneLogo from "../../../assets/img/icons/scenelogo.svg";
import moreReward from "../../../assets/img/icons/moreReward.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "react-responsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAccountInfo } from "../../../redux/actions/settings";
import { Chip } from "@mui/material";
import { getAllPersonas } from "../../../redux/actions/dashboard";
// import { name } from "ejs";
const SearchOverlay = ({ open, onClose }) => {
  const [persona, setPersona] = useState("");
  const [primaryPersona, setPrimaryPersona] = useState();
  // const [power, setPower] = useState("");
  const [brand, setBrand] = useState("");
  // const [selectedLocations, setSelectedLocations] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [showResults, setShowResults] = useState(false); // Track the visibility of "Show Results"
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [personaData, setPersonaData] = useState();
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  // const [searchEmail,setSearcEmail]=useState([]);
  // const [searchPhone,setSearchPhone]=useState([]);

  // const [searchName,setSearcName]=useState([]);
  const [selectedPower, setSelectedPower] = useState("");
  const { personas } = useSelector((state) => state.dashboard);
  const [selectedLocation, setSelectedLocation] = useState("");
  const dispatch = useDispatch();
  const { filterData } = useSelector((state) => state?.dashboard);
  const { token } = useSelector((state) => state?.auth);
  const { accountInfo } = useSelector((state) => state.settings);
  const handleClose = () => {
    onClose(false);
    setShowResults(false);
    setSearchQuery("");
    setResults([]);
    setSelectedPersonas([]);
    setSelectedBrands([]);
    setSelectedPower("");
    setSelectedLocation("");
    setPostalCode("");
    // setSearchPhone("");
    // setSearcName("");

    dispatch(resetFilters());
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const handleShowResults = async () => {
    setShowResults(true);
    setResults(filterData);
    dispatch(
      getFilterInfo(
        token,
        selectedBrands,
        selectedPersonas,
        selectedPower,
        selectedLocation,
        postalCode,
        searchQuery
        // searchName,
        // searchEmail,
        // searchPhone,
      )
    );
  };
  useEffect(() => {
    // if (data && data.length > 0) {
    console.log("Received Data:", filterData);
    // }
  }, [filterData]);
  console.log(token);
  const handleSave = () => {
    setShowResults(true);
  };

  const getInitials = (name) => {
    const names = name?.split(" ");
    const initials = names
      ?.map((n) => n[0])
      .join("")
      ?.toUpperCase();
    return initials;
  };

  const handlePersonaSelect = (personaId) => {
    setSelectedPersonas((prev) => {
      if (prev.includes(personaId)) {
        return prev.filter((id) => id !== personaId); // Remove personaId from selected list
      } else {
        return [...prev, personaId]; // Add personaId to selected list
      }
    });
  };

  // const handleLocationSelect = (location) => {
  //   setSelectedLocation((prev) =>
  //     prev.some((item) => item.city === location.city)
  //       ? prev.filter((item) => item.city !== location.city)
  //       : [...prev, location]
  //   );
  // };

  const handleBrandSelect = (brand) => {
    setSelectedBrands((prev) =>
      prev.includes(brand)
        ? prev.filter((item) => item !== brand)
        : [...prev, brand]
    );
  };
  const handleSelectPower = (value) => {
    // Check if the value is "50% and below"
    if (value === "50% and below") {
      setSelectedPower("50"); // Set to "50" for sending values under 50
    } else {
      // Extract the numeric part of the value (e.g., "30%" -> 30)
      const numericValue = value.replace("%", "");
      setSelectedPower(numericValue); // Set the numeric value
    }
  };
  // useEffect(() => {
  //   //    setLoading(true);
  //   dispatch(getAllPersonas(token));
  // }, [token]);

  useEffect(() => {
    if (personas) {
      setPersonaData(personas);
    }
  }, [personas]);
  // useEffect(() => {
  //   dispatch(getAccountInfo());
  // }, []);
  // useEffect(() => {
  //   dispatch(getAllItems());
  // }, [dispatch]);
  useEffect(() => {
    if (accountInfo) {
      localStorage.setItem("roles", accountInfo?.roles);
    }
  }, [accountInfo]);
  console.log("acc:", accountInfo);

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.trim()) {
      const isPhoneNumber = /^\d+$/.test(query.trim());

      if (filterData && Array.isArray(filterData)) {
        const filteredResults = filterData.filter((account) => {
          if (isPhoneNumber) {
            return account?.number?.toString().includes(query);
          } else {
            const queryParts = query.split(" ");
            return queryParts.some((part) =>
              account?.fullname?.toLowerCase().includes(part)
            );
          }
        });
        setResults(filteredResults); // Update the results state with locally filtered data
      }

      // Dispatch the search action to fetch additional results from the API
      dispatch(
        getFilterInfo(
          token, // Token from state or props
          selectedBrands,
          selectedPersonas,
          selectedPower,
          selectedLocation,
          postalCode,
          query // Pass the search query
        )
      );
    } else {
      setResults([]); // Clear results if the query is empty
    }
  };

  const logoItems = [
    {
      src: CocaColaIcon,
      alt: "CocaCola",
      value: "BrandA",
      text: "Coca Cola",
    },
    {
      src: ikeaLogo,
      alt: "Ikea",
      value: "BrandB",
      text: "Ikea",
    },
    {
      src: mazdaIcon,
      alt: "Mazda",
      value: "BrandC",
      text: "Mazda",
    },
    {
      src: rvLog,
      alt: "RV",
      value: "BrandD",
      text: "Rove Concepts",
    },
    {
      src: hrLogo,
      alt: "HR",
      value: "BrandE",
      text: "Holt Renfrew",
    },
    {
      src: premium,
      alt: "Premium",
      value: "BrandF",
      text: "Premium lounge",
    },
    {
      src: moreRewards,
      alt: "Scene",
      value: "MoreReward",
      text: "More Rewards",
    },
    {
      src: aeroplanLogo,
      alt: "Aeroplan",
      value: "BrandG",
      text: "Aeroplan",
    },
    {
      src: SceneLogo,
      alt: "Scene",
      value: "Scene",
      text: "Scene Rewards",
    },
  ];
  const RotatingArrowIcon = ({ open }) => (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: open ? "rotate(0deg)" : "rotate(180deg)", // Arrow points down initially
        transition: "transform 0.3s ease",
        marginRight: "4px",
      }}
    >
      <path
        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
        fill="#002857"
      />
    </svg>
  );
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            xs: 280,
            sm: 260,
            md: 450,
          },
          padding: "20px",
          backgroundColor: "#F5F7F9",
          justifyContent: "space-between",
          height: "95%",
        },
      }}
    >
      {showResults ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            {/* Close Button */}
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 10,
              }}
            >
              <IconButton onClick={handleClose} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Search Field */}
            <Box sx={{ mt: 3, mb: 2 }}>
              <TextField
                fullWidth
                placeholder="Search with name or phone number"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                InputLabelProps={{
                  shrink: false,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                    borderRadius: "10px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "11px 20px",
                    fontWeight: "500",
                  },
                  "& .MuiOutlinedInput-input::placeholder": {
                    color: "#002857",
                    opacity: 1,
                    fontSize: "16px",
                  },
                }}
              />
            </Box>

            {/* Results List */}
            <List>
              <h4
                style={{
                  fontSize: "16px",
                  color: "#002857",
                  marginBottom: "10px",
                  marginLeft: "4%",
                }}
              >
                {results.length > 0
                  ? `${results.length} results found`
                  : "0 results"}
              </h4>
              {results.length > 0 &&
                results.map((filterData, index) => (
                  <ListItem
                    style={{
                      fontFamily: "Gotham",
                      marginLeft: "1%",
                      color: "#002857",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "space-evenly",
                      flexDirection: "row",
                    }}
                    key={index}
                  >
                    <span>
                      Name: {filterData?.fullName || "Name not available"}
                    </span>
                    <span>
                      Phone:{" "}
                      {filterData?.phoneNumber || "Phone number not available"}
                    </span>
                  </ListItem>
                ))}
            </List>
          </Box>

          {/* Show Results Button */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mt: 4,
            }}
          >
            <Button
              sx={{ borderRadius: "20px", width: "60%" }}
              variant="contained"
              onClick={handleShowResults}
            >
              Show Results
            </Button>
          </Box>
        </>
      ) : (
        // Render dropdowns and inputs
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            {/* Close Icon */}
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 10,
              }}
            >
              <IconButton onClick={handleClose} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ width: "100%", mb: 4, mt: 10 }}>
              <Accordion
                sx={{ boxShadow: "none", borderRadius: "10px !important" }}
              >
                <AccordionSummary
                  sx={{ minHeight: "41px" }}
                  expandIcon={<RotatingArrowIcon open={open} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                      marginRight: "5%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#002857",
                        fontWeight: "500",
                        fontFamily: "Gotham",
                      }}
                    >
                      PERSONAS
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {selectedPersonas.length > 2 ? (
                        <>
                          {selectedPersonas
                            .slice(0, 2)
                            .map((persona, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 7px",
                                  borderRadius: "5px",
                                  backgroundColor: "#F5F7F9",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  color: "#002857",
                                  fontFamily: "Gotham",
                                }}
                              >
                                {persona}
                              </span>
                            ))}
                          <span
                            style={{
                              padding: "2px 7px",
                              borderRadius: "5px",
                              backgroundColor: "#F5F7F9",
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#002857",
                              fontFamily: "Gotham",
                            }}
                          >
                            +{selectedPersonas.length - 2}
                          </span>
                        </>
                      ) : (
                        <>
                          {selectedPersonas.map((persona, index) => (
                            <span
                              key={index}
                              style={{
                                padding: "2px 7px",
                                borderRadius: "5px",
                                backgroundColor: "#F5F7F9",
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#002857",
                                fontFamily: "Gotham",
                              }}
                            >
                              {persona}
                            </span>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      gridTemplateColumns: "repeat(2, auto)",
                      columnGap: "25px",
                      rowGap: "10px",
                      padding: 0,
                    }}
                  >
                    {personaData
                      ?.filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex(
                            (t) => t.personaLabel === value.personaLabel
                          )
                      )
                      .map((email) => (
                        <Chip
                          key={email.personaId}
                          label={email.personaLabel}
                          variant="filled"
                          style={{
                            width: isMobile ? "100px" : "190px",
                            height: "28px",
                            fontSize: "14px",
                            borderRadius: "5px",
                            fontFamily: "Gotham",
                            color: "#002857",
                            backgroundColor: "#F5F7F9",
                            border: selectedPersonas.includes(email.personaId)
                              ? "3px solid #02afef"
                              : "none",
                          }}
                          onClick={() => {
                            handlePersonaSelect(email.personaId);
                          }}
                        />
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Accordion for Buying Power */}
            <Box sx={{ width: "100%", mb: 4 }}>
              <Accordion
                sx={{ boxShadow: "none", borderRadius: "10px !important" }}
              >
                <AccordionSummary
                  sx={{
                    minHeight: "41px",
                  }}
                  expandIcon={<RotatingArrowIcon open={true} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  {/* Left-aligned text */}
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#002857",
                      fontWeight: "500",
                      fontFamily: "Gotham",
                      flexGrow: "1",
                    }}
                  >
                    BUYING POWER
                  </span>

                  {/* Right-aligned selected value */}
                  <span
                    style={{
                      padding: selectedPower ? "2px 7px" : "0",
                      borderRadius: "5px",
                      backgroundColor: "#F5F7F9",
                      fontSize: "10px",
                      fontWeight: "400",
                      color: "#002857",
                      fontFamily: "Gotham",
                      marginRight: "5%",
                    }}
                  >
                    {selectedPower}
                  </span>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    padding: "8px 0px 16px",
                  }}
                >
                  {/* Menu Items for selection */}
                  <MenuItem onClick={() => handleSelectPower("5%")}>
                    5%
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectPower("10%")}>
                    10%
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectPower("20%")}>
                    20%
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectPower("30%")}>
                    30%
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectPower("40%")}>
                    40%
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectPower("50% and below")}>
                    50% and below
                  </MenuItem>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Accordion for Brands */}
            <Box sx={{ width: "100%", mb: 4 }}>
              <div>
                <Accordion
                  sx={{ boxShadow: "none", borderRadius: "10px !important" }}
                >
                  <AccordionSummary
                    sx={{ minHeight: "41px" }}
                    expandIcon={<RotatingArrowIcon open={open} />}
                    aria-controls="brands-content"
                    id="brands-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                        marginRight: "5%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#002857",
                          fontWeight: "500",
                          fontFamily: "Gotham",
                        }}
                      >
                        BRANDS
                      </span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {selectedBrands.length > 2 ? (
                          <>
                            {selectedBrands.slice(0, 2).map((brand, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 7px",
                                  borderRadius: "5px",
                                  backgroundColor: "#F5F7F9",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  color: "#002857",
                                  fontFamily: "Gotham",
                                }}
                              >
                                {brand}
                              </span>
                            ))}
                            <span
                              style={{
                                padding: "2px 7px",
                                borderRadius: "5px",
                                backgroundColor: "#F5F7F9",
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#002857",
                                fontFamily: "Gotham",
                              }}
                            >
                              +{selectedBrands.length - 2}
                            </span>
                          </>
                        ) : (
                          <>
                            {selectedBrands.map((brand, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 7px",
                                  borderRadius: "5px",
                                  backgroundColor: "#F5F7F9",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  color: "#002857",
                                  fontFamily: "Gotham",
                                }}
                              >
                                {brand}
                              </span>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        gridTemplateColumns: isMobile
                          ? "repeat(2, 1fr)"
                          : "repeat(4, 1fr)",
                        columnGap: "8px",
                        rowGap: "19px",
                        padding: 0,
                      }}
                    >
                      {logoItems.map(({ src, alt, value, text }, index) => (
                        <MenuItem
                          key={index}
                          value={value}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "10px",
                            padding: "6px",
                            borderRadius: "5px",
                            color: "#002857",
                            backgroundColor: "#F5F7F9",
                            border: selectedBrands.includes(text)
                              ? "3px solid #02afef"
                              : "none",
                            width: "100%",
                          }}
                          onClick={() => handleBrandSelect(text)}
                        >
                          <img
                            src={src}
                            alt={alt}
                            width={value === "MoreReward" ? "55" : ""}
                            // height="40"
                            // width="60"
                            style={{
                              marginTop:
                                value === "BrandG"
                                  ? "13px"
                                  : value === "BrandA"
                                  ? "11px"
                                  : value === "BrandF"
                                  ? "7px"
                                  : "4px",
                            }}
                          />
                          <span
                            style={{
                              marginTop:
                                value === "BrandG"
                                  ? "7px"
                                  : value === "BrandF"
                                  ? "17px"
                                  : value === "BrandA"
                                  ? "18px"
                                  : value === "MoreReward"
                                  ? "12px"
                                  : value === "BrandD"
                                  ? "12px"
                                  : "13px",
                              fontWeight: "500",
                            }}
                          >
                            {text}
                          </span>
                        </MenuItem>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Box>

            {/* Accordion for Location */}
            <Box sx={{ width: "100%", mb: 4 }}>
              <Accordion
                sx={{ boxShadow: "none", borderRadius: "10px !important" }}
              >
                <AccordionSummary
                  sx={{ minHeight: "41px" }}
                  expandIcon={<RotatingArrowIcon open />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                >
                  {/* Left-aligned text */}
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#002857",
                      fontWeight: "500",
                      fontFamily: "Gotham",
                      flexGrow: "1",
                    }}
                  >
                    LOCATION
                  </span>

                  {/* Right-aligned selected value */}
                  <span
                    style={{
                      padding: selectedLocation ? "2px 7px" : "0",
                      borderRadius: "5px",
                      backgroundColor: "#F5F7F9",
                      fontSize: "10px",
                      fontWeight: "400",
                      color: "#002857",
                      fontFamily: "Gotham",
                      marginRight: "5%",
                    }}
                  >
                    {selectedLocation}
                  </span>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    padding: "8px 0px 16px",
                  }}
                >
                  {/* Menu Items for selection */}
                  {accountInfo?.additional_locations ? (
                    <>
                      {accountInfo?.address_line_1 && (
                        <MenuItem
                          onClick={() =>
                            handleLocationSelect(accountInfo.address_line_1)
                          }
                        >
                          {accountInfo.address_line_1}
                          {accountInfo?.address_line_2}
                        </MenuItem>
                      )}
                      {accountInfo?.location_name && (
                        <MenuItem
                          onClick={() =>
                            handleLocationSelect(accountInfo.location_name)
                          }
                        >
                          {accountInfo.location_name}
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <p>No locations available</p>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Postal Code */}
            <TextField
              fullWidth
              placeholder="POSTAL CODE"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              InputLabelProps={{
                shrink: false,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "13px 16px",
                  fontWeight: "500",
                },
                "& .MuiOutlinedInput-input::placeholder": {
                  color: "#002857",
                  opacity: 1,
                  fontSize: "16px",
                },
              }}
            />
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mt: 4,
            }}
          >
            <Button
              sx={{ borderRadius: "20px", width: "60%" }}
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default SearchOverlay;
