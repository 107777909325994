import { React, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Switch,
  Box,
  Menu,
  MenuItem,
  Button,
  Select,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { Skeleton } from "antd";
import SegmentsTab from "../../components/Reports/SegmentsTab";
import IndividualsTab from "../../components/Reports/IndividualsTab";
import CustomerDetailDrawer from "../../components/common/CustomerDetailDrawer";
import { getAllCutomerData } from "../../redux/actions/co-pliot";
import "./style.scss";
import useWindowSize from "../../hooks/useWindowSize";
import ButtonRangePicker from "../../components/common/ButtonRangePicker";
import { borderRadius } from "polished";
import searchicon from "../../assets/img/searchicon.png";
import filter from "../../assets/img/filter.png";
import icon from "../../assets/img/icon.png";
import CreateAudience from "./CreateAudience";
const filterFormat = "YYYY-MM-DD";

const StyledTab = styled(Tab)({
  textTransform: "none",
  color: "#999999",
  fontSize: "16px",
  height: "35px",
  fontWeight: "500",

  // marginLeft:'-30px,
  "&.Mui-selected": {
    color: "#002857",
    padding: "0px",
    minWidth: "55px",
    fontWeight: "700",
    // marginRight:'20px',
  },
  ".&.MuiTabs-indicator": {
    top: "33px",
    // backgroundColor:"black"
  },
  // "& .MuiTabs-indicator": {
  //   backgroundColor: "black", // Change the tab indicator color here
  // },
  ".&.MuiTabs-scroller": {
    top: "33px",
    marginBottom: "10px",
  },
});
const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    bottom: "8px",
    height: "4px",
    borderRadius: "20px",
  },
});

const Reports = () => {
  const dispatch = useDispatch();

  const { customer, loadingCustomer } = useSelector((state) => state.coPilot);
  const { width } = useWindowSize();
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Lead generation");
  const [selected, setSelected] = useState("Audiences");
  const [select, setSelect] = useState("Audience");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: "selection",
  });

  const handleFilter = (dates) => {
    if (dates.startDate && dates.endDate) {
      dispatch(
        getAllCutomerData({
          start_date: moment(dates.startDate).format(filterFormat),
          end_date: moment(dates.endDate).format(filterFormat),
        })
      );
      setFilters({
        startDate: dates.startDate,
        endDate: dates.endDate,
        key: "selection",
      });
    }
  };
  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setTimeout(() => {
      handleClose();
    }, 200);
  };
  const loadData = () => {
    if (filters) {
      dispatch(
        getAllCutomerData({
          start_date: moment(filters.startDate).format(filterFormat),
          end_date: moment(filters.endDate).format(filterFormat),
        })
      );
    } else {
      dispatch(getAllCutomerData());
    }
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    setStartDate(moment(filters.startDate).format(filterFormat));
    setEndDate(moment(filters.endDate).format(filterFormat));
  }, [filters]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSelect = (option) => {
    setSelected(option);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [isOpened, setIsOpened] = useState(false);
  const toggleMobileDropdown = () => {
    setIsOpened(!isOpened);
  };

  const handleSelected = (options) => {
    setSelect(options);
    setIsOpened(false);
  };
  return (
    <Grid
      container
      spacing={2}
      style={{
        marginRight: isMobile ? "7px" : "",
        overflow: isMobile ? "hidden" : "",
      }}
    >
      <Grid container style={{ paddingTop: "1%" }} xs={12}>
        {/* <Grid container spacing={2}> */}
        <Grid item xs={6}>
          <Typography
            variant="h6"
            className="blue bold-600"
            style={{ marginLeft: "10px" }}
            sx={{ pt: isMobile ? 6 : 1, pl: isMobile ? 2 : 2 }}
          >
            Insights
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomerDetailDrawer
            isOpen={isDrawerOpen}
            onClose={onClose}
            customer={customer}
            isMobile={isMobile}
            isLoading={loadingCustomer}
            onLoadData={loadData}
          />

          {width > 1024 ? (
            <div
              style={{
                display: "flex",
                flexDirection: " row",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  alignSelf: "center",
                  marginRight: "10px",
                }}
              >
                <Box>
                  <img
                    src={searchicon}
                    alt="Search Icon"
                    style={{
                      width: 22,
                      height: 22,
                      marginRight: 12,
                      marginBottom: "5px",
                    }}
                  />
                  <img
                    src={icon}
                    alt="Icon"
                    style={{
                      width: 32,
                      height: 32,
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                    onClick={handleIconClick}
                  />
                  <img
                    src={filter}
                    alt="Filter Icon"
                    style={{ width: 32, height: 32 }}
                  />
                </Box>
              </div>
              <div className="copilot-datetime" style={{ paddingTop: "10px" }}>
                {loading ? (
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ width: "180px" }}
                  />
                ) : (
                  <div>
                    <ButtonRangePicker
                      onFilter={handleFilter}
                      showIcon={false}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="showFilter"
              style={{ paddingLeft: isMobile ? "80%" : "158px" }}
            >
              <ButtonRangePicker showIcon={true} onFilter={handleFilter} />
            </div>
          )}
        </Grid>
        {/* </Grid> */}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            marginTop: "10px",
            borderRadius: "10px",
            width: "15%",
          },
        }}
      >
        <MenuItem
          style={{
            backgroundColor:
              selectedOption === "Lead generation" ? "#e0f7fa" : "",
            color: "#002857",
            fontSize: "13px",
            paddingRight: "150px",
            fontWeight: "500",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          onClick={() => handleSelectOption("Lead generation")}
        >
          Lead generation
        </MenuItem>
        <MenuItem
          style={{
            backgroundColor:
              selectedOption === " Add campaign" ? "#e0f7fa" : "",
            color: "#002857",
            fontSize: "13px",
            paddingRight: "150px",
            fontWeight: "500",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          onClick={() => handleSelectOption(" Add campaign")}
        >
          Add campaign
        </MenuItem>
        <MenuItem
          style={{
            backgroundColor:
              selectedOption === "Brand affinity" ? "#e0f7fa" : "",
            color: "#002857",
            fontSize: "13px",
            paddingRight: "150px",
            fontWeight: "500",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          onClick={() => handleSelectOption("Brand affinity")}
        >
          Brand affinity
        </MenuItem>
      </Menu>
      <Grid
        item
        xl={12}
        xs={12}
        sx={{
          pl: isMobile ? 0 : 2,
          marginLeft: isMobile ? 0 : "12px",
          marginBottom: "35px",
          marginTop: "14px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#002857",
                    fontFamily: "Plus Jakarta Sans",
                  }}
                >
                  Audiences
                </Typography>
                <Switch
                  onChange={() => setActiveTab(activeTab === 0 ? 1 : 0)}
                  sx={{
                    padding: "7px",
                    color: "primary",
                    "& .MuiSwitch-switchBase": {
                      transitionDuration: "800ms",
                    },
                    "& .MuiSwitch-track": {
                      borderRadius: 22 / 2,
                      opacity: 1,
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#002857",
                    fontFamily: "Plus Jakarta Sans",
                  }}
                >
                  Individuals
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginRight: "3%",
                  }}
                >
                  <button
                    onClick={toggleMobileDropdown}
                    style={{
                      padding: "8px 12px",
                      fontSize: "12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      width: "12rem",
                      color: "#002857",
                      fontWeight: "500",
                      fontFamily: "Gotham",
                    }}
                  >
                    {select || "Select an option"}
                    <svg
                      width="13"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginLeft: "52%",
                        transform: isOpened ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path d="M0 0L6.5 7L13 0H0Z" fill="#002857" />
                    </svg>
                  </button>

                  {isOpened && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        width: "90%",
                        padding: "10px",
                        marginTop: "8px",
                        fontFamily: "Gotham",
                        fontSize: "12px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                        <li
                          onClick={() => handleSelected("Location")}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            color: "#002857",
                            fontWeight: "500",
                          }}
                        >
                          Location
                        </li>
                        <li
                          onClick={() => handleSelected("Audiences")}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            color: "#002857",
                          }}
                        >
                          Audiences
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <Button
                  style={{
                    backgroundColor: "#707070",
                    color: "white",
                    marginRight: "2%",
                    fontFamily: "Gotham",
                    fontSize: "12px",
                    height: "32px",
                    fontWeight: "500",
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={{
                    backgroundColor: "#02afef",
                    color: "white",
                    fontFamily: "Gotham",
                    fontSize: "12px",
                    height: "32px",
                    fontWeight: "500",
                  }}
                  onClick={showModal}
                >
                  Create Audience
                </Button>
              </Box>
            </Box>
          ) : (
            <StyledTabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              style={{ height: "35px" }}
            >
              <StyledTab
                label="Audiences"
                disableRipple={true}
                disableFocusRipple={true}
                sx={{
                  pl: 0,
                  marginLeft: "0px",
                  height: "35px",
                  fontSize: "16px",
                  fontFamily: "Plus Jakarta Sans",
                }}
              />
              <StyledTab
                label="Individuals"
                disableRipple={true}
                disableFocusRipple={true}
                sx={{
                  marginLeft: "45px",
                  fontSize: "16px",
                  fontFamily: "Plus Jakarta Sans",
                }}
              />
            </StyledTabs>
          )}

          {!isMobile && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "3%",
                }}
              >
                <button
                  onClick={toggleDropdown}
                  style={{
                    padding: "8px 12px",
                    fontSize: "12px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    width: "12rem",
                    color: "#002857",
                    fontWeight: "500",
                    fontFamily: "Gotham",
                  }}
                >
                  {selected || "Select an option"}
                  <svg
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: "52%",
                      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    <path d="M0 0L6.5 7L13 0H0Z" fill="#002857" />
                  </svg>
                </button>

                {isOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "0",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "90%",
                      padding: "10px",
                      marginTop: "8px",
                      fontFamily: "Gotham",
                      fontSize: "12px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                      <li
                        onClick={() => handleSelect("Location")}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          color: "#002857",
                        }}
                      >
                        Location
                      </li>
                      <li
                        onClick={() => handleSelect("Audiences")}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          color: "#002857",
                        }}
                      >
                        Audiences
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <Button
                style={{
                  backgroundColor: "#707070",
                  color: "white",
                  marginRight: "2%",
                  fontFamily: "Gotham",
                }}
              >
                Edit
              </Button>
              <Button
                style={{
                  backgroundColor: "#02afef",
                  color: "white",
                  width: "22%",
                  fontFamily: "Gotham",
                }}
                onClick={showModal}
              >
                Create Audience
              </Button>
            </Box>
          )}
        </Box>
        <CreateAudience visible={isModalVisible} onCancel={handleCancel} />
      </Grid>

      <Grid item xs={12}>
        {activeTab === 0 ? (
          <SegmentsTab startDate={startDate} endDate={endDate} />
        ) : activeTab === 1 ? (
          <IndividualsTab startDate={startDate} endDate={endDate} />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Reports;
