import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Divider,
  Typography,
} from "antd";
import { React, useState, useRef, useEffect } from "react";
import { Box, IconButton, Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { submitLocationFormData } from "../../../redux/actions/settings";
import { useMediaQuery } from "react-responsive";
import EditIcon from "@mui/icons-material/Edit";
import "./form-profile.scss";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import star from "../../../assets/img/star.png";

const FormOrganization = ({ isCancel = true, onClose }) => {
  const handleFinish = (values) => {};
  const [jsonData, setJsonData] = useState(null);
  // const { accountInfo } = useSelector(
  //   (state) => state.settings
  // );
  const { uploadCSV, loading, error } = useSelector(
    (state) => state.form || {}
  );
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      return phoneNumber;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;
  };
  const csvToJson = (csv) => {
    const lines = csv
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "");

    const parseCsvLine = (line) => {
      const regex = /("([^"]*)"|[^,]+|(?<=,)(?=,))/g;
      const matches = [];
      let match;
      while ((match = regex.exec(line)) !== null) {
        matches.push(match[2] !== undefined ? match[2] : match[0].trim());
      }
      return matches;
    };

    const headers = parseCsvLine(lines[0]);

    const jsonData = lines.slice(1).map((line) => {
      const values = parseCsvLine(line);
      const obj = {};
      headers.forEach((header, index) => {
        obj[header.trim()] = values[index]?.trim() || null;
      });
      return obj;
    });

    return jsonData;
  };

  const fileInputRef = useRef(null);
  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;

        const json = csvToJson(text);
        const transformedData = json.map((entry) => {
          const transformedEntry = {};
          for (const [key, value] of Object.entries(entry)) {
            let formattedKey = key
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "_")
              .replace(/-/g, "_");
            formattedKey = formattedKey.replace(/-/g, "_");
            if (formattedKey == "month_to_month_(y/n)") {
              formattedKey = "month_to_month";
            }
            transformedEntry[formattedKey] = value;
          }
          return transformedEntry;
        });

        setJsonData(transformedData); // Update state with transformed JSON data
        console.log("Transformed JSON Data:", transformedData); // Log transformed data

        // Reset the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const [formData, setFormData] = useState({});
  const [coFormData, setCoFormData] = useState({});
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "country" && { state_province: null }), // Reset state when country changes
    }));
    setCoFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "country" && { state_province: null }), // Reset state when country changes
    }));
  };

  const stateOptions = {
    US: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    Canada: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],
  };

  const submitData = () => {
    try {
      dispatch(submitLocationFormData(formData, jsonData));
      console.log();
      //  window.location.href = window.location.href;
      onClose();
    } catch (error) {
      console.error("Failed to update account info:", error);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };
  const fetchImageUrl = async () => {
    try {
      let userIDSub = localStorage.getItem("userIDSub");
      const response = await axios.get(
        `https://qqiud3gxsa.execute-api.us-east-1.amazonaws.com/api/get-image-url?userId=${userIDSub}`
      );
      setImageUrl(response.data.url);
    } catch (error) {
      console.error("Error fetching image URL:", error);
    }
  };
  useEffect(() => {
    fetchImageUrl();
  }, []);

  const handleUpload = async (fileData) => {
    if (!fileData) {
      alert("Please select a file first!");
      return;
    }

    try {
      // Get the signed URL from your backend
      let userIDSub = localStorage.getItem("userIDSub");

      const response = await axios.get(
        `https://qqiud3gxsa.execute-api.us-east-1.amazonaws.com/api/upload-url?userId=${userIDSub}`
      );
      const { url } = response.data;

      // Upload the image to S3 using the signed URL
      await axios.put(url, fileData, {
        headers: {
          "Content-Type": "image/png",
        },
      });
      fetchImageUrl();

      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Failed to upload file.");
    }
  };

  function EditableAvatar({ imageUrl, onEdit }) {
    return (
      <div style={{ display: "flex", marginBottom: "4%" }}>
        <Avatar
          src={imageUrl}
          alt="Profile picture"
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
        <div style={{ marginTop: "27px" }}>
          <IconButton
            onClick={onEdit}
            size="small"
            color="primary"
            style={{
              color: "#999999",
              backgroundColor: "white",
              border: "1px solid #999999",
              padding: "2px",
              marginLeft: "-8px",
            }}
          >
            <EditIcon style={{ fontSize: "0.7rem" }} />
          </IconButton>
        </div>
      </div>
    );
  }
  return (
    <>
      <div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          style={{ display: "none" }} // Hide the file input
        />
        <EditableAvatar imageUrl={imageUrl} onEdit={handleEditClick} />
      </div>
      <Form className="formProfile" layout="vertical" onFinish={handleFinish}>
        <Row gutter={[54, 15]}>
          <Col md={12} xs={24}>
            <Form.Item
              name="location name"
              rules={[
                {
                  required: true,
                  message: "Location name is required",
                },
              ]}
              label={
                <span
                  className="custom-label"
                  style={{
                    color: "#002857",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  Location Name
                  <img
                    src={star}
                    alt="Star"
                    style={{
                      width: "6px",
                      height: "5px",
                      marginBottom: "4px",
                    }}
                  />
                </span>
              }
            >
              <Input
                placeholder=""
                value={formData?.location_Name}
                defaultValue={formData?.location_name}
                onChange={(e) =>
                  handleInputChange("location_name", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Row gutter={[54, 15]} style={{ width: "100%", marginLeft: "0%" }}>
            <Col md={12} xs={24}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Phone number is required",
                  },
                ]}
                label={
                  <span
                    className="custom-label"
                    style={{
                      color: "#002857",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    Phone Number
                    <img
                      src={star}
                      alt="Star"
                      style={{
                        width: "6px",
                        height: "5px",
                        marginBottom: "4px",
                      }}
                    />
                  </span>
                }
              >
                <Input
                  placeholder=""
                  defaultValue={formatPhoneNumber(formData?.phone)}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <span
                    style={{
                      color: "#002857",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    Email Address
                    <img
                      src={star}
                      alt="Star"
                      style={{
                        width: "6px",
                        height: "5px",
                        marginBottom: "4px",
                      }}
                    />
                  </span>
                }
                name="Email"
              >
                <Input
                  placeholder=""
                  defaultValue={formData?.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col md={12} xs={24}>
            <Form.Item
              label={<span style={{ color: "#002857" }}>Street Address</span>}
              name="Street Address"
            >
              <Input
                placeholder=""
                // defaultValue={formData?.address_line_1} onChange={(e) => handleInputChange(
                //     "Street Address", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={<span style={{ color: "#002857" }}>Suite, Unit, Apt</span>}
              name="Suite, Unit, Apt"
            >
              <Input
                placeholder=""
                //  defaultValue={formData?.address_line_2} onChange={(e) => handleInputChange(
                // "Suite, Unit, Apt", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={<span style={{ color: "#002857" }}>City</span>}
              name="City"
            >
              <Input
                placeholder=""
                defaultValue={formData?.City}
                onChange={(e) => handleInputChange("City", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span style={{ color: "#002857" }}>
                  {formData.country === "Canada"
                    ? "Province"
                    : formData.country === "US"
                    ? "State"
                    : "State or Province"}
                </span>
              }
              name="state_province" // Updated to match the desired payload key
            >
              <Select
                value={formData.state_province} // Bind to state_province in formData
                placeholder={
                  formData.state_province
                    ? formData.state_province
                    : formData.country === "Canada"
                    ? "Select a Province"
                    : formData.country === "US"
                    ? "Select a State"
                    : "Select an option"
                }
                name="state_province" // Ensure consistency
                onChange={(value) => handleInputChange("state_province", value)} // Update state_province on change
                suffixIcon={
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                      fill="#002857"
                    />
                  </svg>
                }
              >
                {formData?.country &&
                  stateOptions[formData?.country]?.map((state_province) => (
                    <Select.Option key={state_province} value={state_province}>
                      {state_province}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span style={{ color: "#002857" }}>
                  {formData.country === "Canada"
                    ? "Postal Code"
                    : formData.country === "US"
                    ? "Zip Code"
                    : "Postal Code"}
                </span>
              }
              name="postalCode"
            >
              <Input
                placeholder=""
                defaultValue={formData?.postal_code}
                onChange={(e) =>
                  handleInputChange("postal_code", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={
                <span className="custom-label" style={{ color: "#002857" }}>
                  Country
                </span>
              }
              name="country"
            >
              <Select
                defaultValue={formData.country}
                onChange={(value) => handleInputChange("country", value)}
                suffixIcon={
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                      fill="#002857"
                    />
                  </svg>
                }
              >
                <option value="US">United States</option>
                <option value="Canada">Canada</option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Box sx={{ width: "76vw", marginLeft: "-45px" }}>
        <Divider />
      </Box>
      <Typography
        style={{
          fontWeight: "500",
          color: "#002857",
          fontSize: "16px",
          marginTop: "4%",
          fontFamily: "Gotham",
        }}
      >
        Upload tenant List{" "}
      </Typography>
      <Typography
        style={{
          fontWeight: "500",
          marginTop: "3%",
          color: "#002857",
          fontSize: "15px",
          fontFamily: "Gotham",
        }}
      >
        <span
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "17px",
            height: "17px",
            color: "#002857",
            borderRadius: "50%",
            border: "1px solid",
            marginRight: "10px",
          }}
        >
          1
        </span>
        Complete template
        <span
          style={{
            color: "#0050BC",
            textDecoration: "underline",
            textDecorationColor: "rgba(0, 80, 188, 0.2)",
            fontWeight: "500",
            fontFamily: "Gotham",
          }}
        >
          {" "}
          tenant list
        </span>
      </Typography>

      <Typography
        style={{
          fontWeight: "500",
          marginTop: "3%",
          color: "#002857",
          fontSize: "15px",
          fontFamily: "Gotham",
        }}
      >
        <span
          style={{
            color: "#002857",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            border: "1px solid",
            marginRight: "10px",
          }}
        >
          2
        </span>
        Upload your tenant list
      </Typography>

      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          mt: 3,
          color: "#002857",
          fontFamily: "Gotham",
        }}
      >
        <Box
          sx={{
            border: "1px dashed #002857",
            borderRadius: "8px",
            backgroundColor: "rgba(150, 224, 255, 0.2)",
            p: 3,
            width: 500,
            cursor: "pointer",
          }}
        >
          <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
            <input
              ref={fileInputRef}
              id="file-upload"
              type="file"
              style={{ display: "none" }}
              accept=".csv"
              onChange={handleFileUpload}
            />

            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3334 26.667V13.0837L14.0001 17.417L11.6667 15.0003L20.0001 6.66699L28.3334 15.0003L26.0001 17.417L21.6667 13.0837V26.667H18.3334ZM10.0001 33.3337C9.08341 33.3337 8.29897 33.0075 7.64675 32.3553C6.99453 31.7031 6.66786 30.9181 6.66675 30.0003V25.0003H10.0001V30.0003H30.0001V25.0003H33.3334V30.0003C33.3334 30.917 33.0073 31.702 32.3551 32.3553C31.7029 33.0087 30.9179 33.3348 30.0001 33.3337H10.0001Z"
                fill="#002857"
              />
            </svg>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{
                fontWeight: "500",
                color: "#000000",
                fontFamily: "Gotham",
              }}
            >
              Upload / Drop
            </Typography>
          </label>
        </Box>
        {file && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              color: "#002857",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                mr: 2,
              }}
            >
              <span
                style={{
                  color: "#000000",
                  fontWeight: "500",
                  fontSize: "15px",
                  marginRight: "6px",
                }}
              >
                ✓
              </span>{" "}
              <span
                style={{
                  color: "#002857",
                  fontWeight: "500",
                  fontFamily: "Gotham",
                }}
              >
                {file.name}
              </span>
            </Typography>
            <IconButton
              onClick={handleRemoveFile}
              size="small"
              sx={{
                color: "#000000",
                fontWeight: "550",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 11, mb: 8 }}>
        <Col xs={24}>
          <div
            className="actions"
            style={{
              display: isMobile ? "flex" : "",
              justifyContent: isMobile ? "center" : "",
            }}
          >
            {isCancel && (
              <Button
                style={{
                  borderRadius: "20px",
                  background: "#999",
                  border: "none",
                  height: "40px",
                  width: "150px",
                  color: "#fff",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  marginRight: "5%",
                }}
                // className="btnCancel"
                htmlType="button"
                onClick={onClose}
              >
                Cancel
              </Button>
            )}
            <Button
              //  className="btnSave"
              style={{
                borderRadius: "20px",
                background: "#002857",
                border: "none",
                height: "40px",
                width: "150px",
                color: "#fff",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
              type="primary"
              htmlType="submit"
              onClick={submitData}
            >
              Save
            </Button>
          </div>
        </Col>
      </Box>
    </>
  );
};

export default FormOrganization;
