import { Divider, Modal } from "antd";
import React, { useState } from "react";
import { Form, Input, Row, Col, Button, Select, Checkbox } from "antd";
import { useMediaQuery } from "react-responsive";
const CreateAudience = ({ visible, onCancel }) => {
  const [value, setValue] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 50) {
      setValue(inputValue);
    }
  };
  const options = [
    "Needs based",
    "Seasonal",
    "Impulse-Driven",
    "Habitual",
    "Trendsetter/Innovation",
    "Convenience-Driven",
    "Power Buyer",
    "Limited Buyer",
    "Foodie Buyer",
    "Sports Buyer",
    "Ethical/Sustainable Buyer",
  ];

  const { Option } = Select;
  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const handleChanges = (value) => {
    setSelectedPersonas(value);
  };

  const [inputs, setInputs] = useState({
    audienceName: "",
    location: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const customDropdownIcon = (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
        fill="#002857"
      />
    </svg>
  );

  return (
    <>
      <Modal
        title={
          <span
            style={{
              color: "#02afef",
              fontSize: "12px",
              fontFamily: "Gotham",
              fontWeight: "500",
            }}
          >
            AUDIENCES
          </span>
        }
        visible={visible}
        onCancel={onCancel}
        width={1000}
        footer={null}
        closeIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#002857"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        }
      >
        <div
          style={{
            fontWeight: "500",
            fontSize: "20px",
            color: "#002857",
            fontFamily: "Gotham",
            margin: "0",
          }}
        >
          Create Audience
        </div>
        <Divider
          style={{
            borderColor: "#c8c8ce",
            width: isMobile ? "80vw" : "65vw",
            marginLeft: "-33px",
          }}
        />
        <Form layout="vertical">
          <Row gutter={[54, 15]}>
            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <span
                    style={{
                      color: "#002857",
                      fontFamily: "Gotham",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginTop: "10px",
                      marginBottom: "7px",
                    }}
                  >
                    Audience Name
                  </span>
                }
                name="audienceName"
              >
                <Input
                  placeholder=""
                  name="audienceName"
                  value={inputs.audienceName}
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "10px",
                    color: inputs.audienceName ? "#002857" : "",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "16px",
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <span
                    style={{
                      color: "#002857",
                      fontFamily: "Gotham",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginTop: "10px",
                      marginBottom: "7px",
                    }}
                  >
                    Location
                  </span>
                }
                name="location"
              >
                <Input
                  placeholder="Zip/Postal Code"
                  name="location"
                  value={inputs.location}
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "10px",
                    color: inputs.location ? "#002857" : "",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "16px",
                  }}
                  className="custom-input"
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <span
                    style={{
                      color: "#002857",
                      fontFamily: "Gotham",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginBottom: "7px",
                    }}
                  >
                    Select Persona(s)
                  </span>
                }
                name="persona"
              >
                <Select
                  mode="multiple"
                  onChange={handleChanges}
                  value={selectedPersonas}
                  optionLabelProp="label"
                  tagRender={(props) => {
                    const { label, value } = props;
                    const isLast =
                      value === selectedPersonas[selectedPersonas.length - 1];
                    return (
                      <span
                        style={{
                          color: "#002857",
                          marginRight: 4,
                          fontFamily: "Plus Jakarta Sans",
                          fontSize: "16px",
                        }}
                      >
                        {label}
                        {!isLast && ","}{" "}
                      </span>
                    );
                  }}
                  suffixIcon={customDropdownIcon}
                  dropdownClassName="custom-dropdown"
                >
                  {options.map((option) => (
                    <Option key={option} value={option} label={option}>
                      <Checkbox
                        checked={selectedPersonas.includes(option)}
                        className="custom-checkbox"
                      >
                        <span style={{ marginLeft: "10px" }}>{option}</span>
                      </Checkbox>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <span
                    style={{
                      color: "#002857",
                      fontFamily: "Gotham",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginBottom: "7px",
                    }}
                  >
                    Age
                  </span>
                }
                name="age"
              >
                <Select
                  suffixIcon={
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                        fill="#002857"
                      />
                    </svg>
                  }
                  className="custom-select"
                >
                  <option value="18-24">18-24 </option>
                  <option value="25-34">25-34</option>
                  <option value="35-44">35-44</option>
                  <option value="45-54">45-54</option>
                  <option value="55-64">55-64</option>
                  <option value="65+">65+</option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <span
                    style={{
                      color: "#002857",
                      fontFamily: "Gotham",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginBottom: "7px",
                    }}
                  >
                    Gender
                  </span>
                }
                name="gender"
              >
                <Select
                  suffixIcon={
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                        fill="#002857"
                      />
                    </svg>
                  }
                  className="custom-select"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Female">Other</option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <span
                    style={{
                      color: "#002857",
                      fontFamily: "Gotham",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginBottom: "7px",
                    }}
                  >
                    Income
                  </span>
                }
                name="income"
              >
                <Select
                  suffixIcon={
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                        fill="#002857"
                      />
                    </svg>
                  }
                  className="custom-select"
                >
                  <option value="0-$29,000">0-$29,000</option>
                  <option value="$30,000-$74,000">$30,000-$74,000</option>
                  <option value="$75,000-$99,000">$75,000-$99,000</option>
                  <option value="$100,000-$149,000">$100,000-$149,000</option>
                  <option value="$150,000">$150,000</option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col md={24} style={{ marginBottom: "8%" }}>
            <Form.Item
              label={
                <span
                  style={{
                    fontWeight: "500",
                    color: "#002857",
                    fontFamily: "Gotham",
                    marginTop: "30px",
                    marginBottom: "7px",
                    fontSize: "15px",
                  }}
                >
                  Describe Your Audience
                </span>
              }
              name="describeYourAudience"
            >
              <Input.TextArea
                rows={3}
                value={value}
                onChange={handleChange}
                style={{
                  width: "100%",
                  borderRadius: "12px",
                  padding: "10px",
                  fontSize: "16px",
                  color: "#002857",
                  resize: "none",
                }}
              />
              <div
                style={{
                  textAlign: "right",
                  color: "#999999",
                  fontSize: "10px",
                  marginTop: "5px",
                  fontWeight: "500",
                }}
              >
                50 word limit
              </div>
            </Form.Item>
          </Col>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "18px",
            marginBottom: "35px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#999999",
              color: "white",
              width: isMobile ? "" : "145px",
              height: "39px",
              borderRadius: "22px",
              fontFamily: "Gotham",
              fontWeight: "bold",
              fontSize: isMobile ? "12px" : "16px",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#002857",
              color: "white",
              width: isMobile ? "" : "145px",
              height: "39px",
              borderRadius: "22px",
              fontFamily: "Gotham",
              fontWeight: "bold",
              fontSize: isMobile ? "12px" : "16px",
            }}
          >
            Create
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CreateAudience;
