import axios from "axios";
import {
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_ERROR,
  GET_LOCATION_INFO_SUCCESS,
  GET_LOCATION_INFO_ERROR,
  LOCATION_INFO_UPLOAD_CSV, SET_LOCATION_FORM_DATA, SUBMIT_LOCATION_FORM_DATA, SET_LOCATION_ERROR, SET_lOCATION_LOADING,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INFO_ERROR,
  UPDATE_ACCOUNT_INFO_ORG,
  UPDATE_ACCOUNT_INFO_ORG_ERROR,
  DELETE_LOCATION_BY_ID,
  DELETE_LOCATION_BY_ID_ERROR,
  INVITE_USER,
  INVITE_USER_ERROR,
  COUNT_USERS_SUCCESS,
  COUNT_USERS_ERROR,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_USERS_INFO_SUCCESS,
  GET_USERS_INFO_ERROR,
  SUBMIT_USER_INFO_SUCCESS,
  SUBMIT_USER_INFO_ERROR,
  DELETE_USER_BY_ID,
  DELETE_USER_BY_ID_ERROR,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_ERROR,
  POST_INFO_SUCCESS,
  POST_INFO_ERROR,
  GET_PARTICULAR_LOCATION,
  GET_PARTICULAR_LOCATION_ERROR
} from "../constant/settings";

export const getAccountInfo = () => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });
    let email = localStorage.getItem("email");
    let tenantID = localStorage.getItem("tenantID");
    let userIdSub = localStorage.getItem("userIDSub");

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/get-account-information`,
      {
        params: {
          email: email,
        },
        headers: {
          tenantId: tenantID,
          userIdSub: userIdSub,
        },
      }
    );
    dispatch({ type: GET_ACCOUNT_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ACCOUNT_INFO_ERROR, payload: error });
  }
};
export const submitLocationFormData = (formData, jsonData) => async (dispatch) => {
  dispatch({ type: SET_lOCATION_LOADING, payload: true });

  try {
    // Retrieve customerId from local storage
    const customer_id = localStorage.getItem('userIDSub');
    const tenant_id = localStorage.getItem('tenantID');
    console.log("Customer ID:", customer_id);
    console.log("Tenant ID:", tenant_id);
    if (!customer_id || !tenant_id) {
      throw new Error('Customer ID or Tenant ID not found in local storage.');
    }

    // Merge formData and jsonData ensuring all formData fields are included in the payload
    const payload = {
      customer_id: customer_id,
      tenant_id: tenant_id,
      csvData: jsonData,
      ...formData, // Add formData to ensure fields not in jsonData are included
    };

    console.log("Final Payload:", payload);

    // Check if payload has numeric keys, if not, call API with just formData
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/add-location`,
        payload
      );

      dispatch({
        type: SUBMIT_LOCATION_FORM_DATA,
        payload: response.data,
      });
    } catch (apiError) {
      console.error(`Failed to submit tenant:`, apiError.message);
      dispatch({
        type: SET_LOCATION_ERROR,
        payload: `Failed to submit tenant: ${apiError.response?.data?.details || apiError.message}`,
      });
    }
  } catch (error) {
    dispatch({
      type: SET_LOCATION_ERROR,
      payload: error.response?.data?.details || 'Failed to submit data.',
    });
  } finally {
    dispatch({ type: SET_lOCATION_LOADING, payload: false });
  }
};




export const getlocationInfo = () => async (dispatch) => {
  try {
    const email = localStorage.getItem("email");
    const customerId =
      localStorage.getItem("userIDSub");
      const token = localStorage.getItem("token");
    // Send email and customerId as query parameters
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/get-all-locations`,
      {
        params: {
          email,  // Pass email as a query parameter
          customerId, // Pass customerId as a query parameter
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("API Response Data:", data);
    dispatch({ type: GET_LOCATION_INFO_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error fetching user info:", error);
    dispatch({
      type: GET_LOCATION_INFO_ERROR,
      payload: error.message || error,
    });
  }
};


export const getlocationInfobyId = (locationId) => async (dispatch) => {
  try {
    // let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/particular-location-info`,
      {
        params: {
          locationId: locationId,
        },
      }
    );

    dispatch({ type: GET_PARTICULAR_LOCATION, payload: data });
  } catch (error) {
    dispatch({ type: GET_PARTICULAR_LOCATION_ERROR, payload: error });
  }
};
export const deleteLocationInfo = (locationID) => async (dispatch) => {
  try {
    // Send DELETE request with location_id in the URL path
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/delete-location/${locationID}`
    );

    // Dispatch actions after deletion
    // dispatch(getAllLocationsInfo()); // Assuming you want to fetch updated location info
    dispatch({ type: DELETE_LOCATION_BY_ID, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_LOCATION_BY_ID_ERROR,
      payload: error.message || error,
    });
  }
};




export const updateAccountInfo = (body) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });

    let tenantID = localStorage.getItem("tenantID");
    let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/update-account-information`,
      body,
      {
        headers: {
          tenantId: tenantID,
          userIdSub: userIdSub,
        },
      }
    );

    dispatch({ type: UPDATE_ACCOUNT_INFO, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_ACCOUNT_INFO_ERROR, payload: error });
  }
};

export const updateAccountOrgInfo = (body) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });

    let tenantID = localStorage.getItem("tenantID");
    let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/update-organization-information`,
      body,
      {
        headers: {
          tenantId: tenantID,
          userIdSub: userIdSub,
        },
      }
    );

    dispatch({ type: UPDATE_ACCOUNT_INFO_ORG, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_ACCOUNT_INFO_ORG_ERROR, payload: error });
  }
};
// export const updateUserLocationInfo = (body) => async (dispatch) => {
//   try {
//     // await dispatch({ type: GET_USER_RATE });

//     let tenantID = localStorage.getItem("tenantID");
//     let customerId = localStorage.getItem("customerID");
//     //Not a correct api for location
//     const { data } = await axios.post(
//       `${process.env.REACT_APP_LOGIN_API_URL}/api/add-location`,
//       body,
//       {
//         headers: {
//           tenantId: tenantID,
//           customerId: customerId,
//         },
//       }
//     );

//     dispatch({ type: UPDATE_USER_INFO_LOC, payload: data });
//   } catch (error) {
//     dispatch({ type: UPDATE_USER_INFO_LOC_ERROR, payload: error });
//   }
// };
export const postUserLocationInfo = (body) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });

    let tenantID = localStorage.getItem("tenantID");
    let customerId = localStorage.getItem("customerID");
    //Not a correct api for location
    const { data } = await axios.post(
      `${process.env.REACT_APP_LOGIN_API_URL}/api/add-location`,
      body,
      {
        headers: {
          tenantId: tenantID,
          customerId: customerId,
        },
      }
    );

    dispatch({ type: POST_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: POST_INFO_ERROR, payload: error });
  }
};

export const inviteUserSES = (email, role) => async (dispatch) => {
  try {
    let tenantID = localStorage.getItem("tenantID");

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/invite-user`,
      {
        email: email,
        tenant_id: tenantID,
        roles: role,
      }
    );

    dispatch({ type: INVITE_USER, payload: data });
    dispatch(getAllUsersInfo());
  } catch (error) {
    dispatch({ type: INVITE_USER_ERROR, payload: error });
  }
};

export const countUsers = () => async (dispatch) => {
  try {
    let tenantID = localStorage.getItem("tenantID");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/count`,
      {
        params: {
          tenant_id: tenantID,
        },
      }
    );
    dispatch({ type: COUNT_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNT_USERS_ERROR, payload: error });
  }
};
export const getAllUsersInfo = () => async (dispatch) => {
  try {
    let tenantID = localStorage.getItem("tenantID");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/all-users`,
      {
        params: {
          tenant_id: tenantID,
        },
      }
    );
    dispatch({ type: GET_ALL_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_USERS_ERROR, payload: error });
  }
};

export const getUsersInfobyId = (userId) => async (dispatch) => {
  try {
    // let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/particular-user-info`,
      {
        params: {
          user_id: userId,
        },
      }
    );

    dispatch({ type: GET_USERS_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_USERS_INFO_ERROR, payload: error });
  }
};

export const submitUserInfo = (body) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/update-user-info`,
      body
    );
    dispatch(getAllUsersInfo());
    dispatch({ type: SUBMIT_USER_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBMIT_USER_INFO_ERROR, payload: error });
  }
};

export const deleteUserById = (userId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/delete-info`,
      {
        params: {
          user_id: userId,
        },
      }
    );
    dispatch(getAllUsersInfo());
    dispatch(countUsers());
    dispatch({ type: DELETE_USER_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_USER_BY_ID_ERROR, payload: error });
  }
};

export const resendInvite = (email) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });

    let tenantID = localStorage.getItem("tenantID");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/resend-invite`,
      {
        email: email,
        tenant_id: tenantID,
      }
    );

    dispatch({ type: RESEND_INVITE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RESEND_INVITE_ERROR, payload: error });
  }
};
