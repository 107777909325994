
import React, { useState, useEffect } from "react";
import { Button, Table, Modal } from "antd";
import { useMediaQuery } from "react-responsive";
import ModalLocation from "./ModalLocation";
import ModalLocationDetail from "./ModalLocationDetail";
import "./style.scss";
import FormOrganization from "./FormOrganization";
import { getlocationInfo, deleteLocationInfo, getlocationInfobyId } from "../../../redux/actions/settings";
import { useDispatch, useSelector } from "react-redux";

const SettingOrganization = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationDetailVisible, setLocationDetailVisible] = useState(false);
  const [locationDetail, setLocationDetail] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false); // New state for delete dialog
  const [currentLocationId, setCurrentLocationId] = useState(null); // Track current location ID for deletion
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const dispatch = useDispatch();
  const { locationInfo, locationInfoById } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getlocationInfo());
  }, [dispatch]);

  useEffect(() => {
    if (locationInfoById) {
      setLocationDetail(locationInfoById);
      setLocationDetailVisible(true);
    }
  }, [locationInfoById])

  useEffect(() => {
    if (Array.isArray(locationInfo)) {
      const formattedData = locationInfo.map((item) => ({
        location: item.location_name || "Unknown Location",
        postalCode: item.postal_code || "Unknown Postal Code",
        city: item.city || "Unknown City",
        location_id: item.location_id,
        created_at: new Intl.DateTimeFormat().format(new Date(item.created_at))
      }));
      setFilteredData(formattedData);
    }
  }, [locationInfo]);

  const onSearch = (value) => {
    const searchValue = value?.toLowerCase() || "";
    setFilteredData(
      locationInfo.filter((item) =>
        item.email.toLowerCase().includes(searchValue)
      )
    );
  };

  const showDeleteDialog = (location_id) => {
    setCurrentLocationId(location_id);
    setDeleteDialogVisible(true);
  };
  const showLocationDetails = (location_id) => {
   dispatch(getlocationInfobyId(location_id));
    setCurrentLocationId(location_id);
  
  }
  const confirmDelete = () => {
    if (currentLocationId) {
      dispatch(deleteLocationInfo(currentLocationId));
      setFilteredData(filteredData.filter(item => item.location_id !== currentLocationId));
    }
    setDeleteDialogVisible(false);
    setCurrentLocationId(null);
  };

  const cancelDelete = () => {
    setDeleteDialogVisible(false);
    setCurrentLocationId(null);
  };

  const handleCloseDetail = () => {
    setLocationDetailVisible(false);
    setLocationDetail(null);
  };


  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "id",
      width: 240,
      render: (text, record) => (
        <div className="viewLocation" >
          <div className="locationInfo">
            <p style={{ fontSize: "14px", fontWeight: "500" }}>{record.location}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Added",
      dataIndex: "Added",
      key: "status",
      width: 140,
      render: (text, record) => (
        <span>{`${record.created_at}`}</span>
      ),
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "status",
      width: 240,
      render: (text, record) => (
        <span>{`${record.postalCode} ${record.location}, ${record.city}`}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (text, record) => (
        <a onClick={() => showLocationDetails(record.location_id)}>Show Details</a>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "status",
      width: 10,
      render: (text, record) => (
        <span style={{ color: "#002857" }}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => showDeleteDialog(record.location_id)}
          >
            <path
              d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
              fill="currentColor"
            />
          </svg>
        </span>
      ),
    },
  ];

  return (
    <>
      <h1
        className="title"
        style={{ display: isMobile ? "contents" : "none", marginLeft: isMobile ? "20px" : "" }}
      >
        Locations
      </h1>
      <div className="location">
        {isModalOpen && (
          <div className="addLocationContainer">
            <FormOrganization onClose={() => setIsModalOpen(false)} />
            <ModalLocation onClose={() => setIsModalOpen(false)} />
          </div>
        )}

        {!isModalOpen && (
          <>
            <div className="locationHeader" style={{ flexDirection: isMobile ? "column" : "" }}>
            
              <Button color="primary" className="btnInvite" onClick={() => setIsModalOpen(true)}>
                Add location
              </Button>
            </div>
            <div>
              <Table
                columns={columns}
                dataSource={filteredData}
                rowKey="location_id"
                scroll={{ x: 800 }}
                pagination={{ pageSize: 7 }}
              />
            </div>
          </>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        visible={deleteDialogVisible}
        onOk={confirmDelete}
        onCancel={cancelDelete}
        okText="Delete"
        okButtonProps={{
          style: {
            backgroundColor: '#002857',
            color: 'white',
          }
        }}
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this location?</p>
      </Modal>
      <ModalLocationDetail
        visible={deleteDialogVisible}
        detail={locationDetail}
        open={locationDetailVisible}
        onClose={handleCloseDetail}
        selectedLocationId={currentLocationId} />
    </>
  );
};

export default SettingOrganization;


