import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import "./style.scss";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import CheckIcon from "../../../../assets/img/marker-icon.svg";
import LineIcon from "../../../../assets/img/line-icon.svg";
import InfoIcon from "../../../../assets/img/information-icon.svg";
import BlackStarIcon from "../../../../assets/img/black-star-icon.svg";
import { PlanList } from "../../../../pages/Onboarding/constants";

function createData(name, free, business, premium) {
  return {
    name,
    free,
    business,
    premium,
  };
}

const rows = [
  createData("Profile views (requests)", 500, 2500, "Unlimited"),
  createData("Reviews", true, true, true),
  createData("Personas", true, true, true),
  createData("Preferences", true, true, true),
  createData("Recommendations", 100, 1000, "Unlimited"),
  createData("AI Recommendations", false, true, true),
  createData("Payment Integrations", "Required", "BYOP", "BYOP"),
  createData("Data Integrations", 3, 20, "Unlimited"),
  createData("Stripe Dashboard App", false, true, true),
  createData("Quickbooks App", false, true, true),
];

function iconDisplay(value) {
  if (typeof value === "boolean") {
    return value ? (
      <img src={CheckIcon} alt="true icon" />
    ) : (
      <img src={LineIcon} alt="false icon" />
    );
  } else if (value === "BYOP") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        {value} <img src={InfoIcon} alt="information icon" />
      </Box>
    );
  }
  return value;
}

const PlanTable = () => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 930 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isSmallMobile = useMediaQuery({ maxWidth: 375 });
  const [updatedPlanList, setUpdatedPlanList] = useState(PlanList);
  const { onboarding } = useSelector((state) => state.onboarding);

  useEffect(() => {
    const updatedPlans = PlanList.map((plan) => {
      const planTitle = plan.title;
      const matchedPlan = onboarding.planInfo.list.find(
        (info) => info.name.includes(planTitle) && info.name.includes("Month")
      );
      return {
        ...plan,
        price: matchedPlan ? matchedPlan.price : plan.price,
      };
    });
    setUpdatedPlanList(updatedPlans);
  }, [onboarding]);

  return (
    <Box className={isTablet || isMobile ? `planTableMobile` : `planTable`}>
      {isTablet ||
        (isMobile && (
          <Typography variant="h6" className="tableHeading">
            Compare Plans
          </Typography>
        ))}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {!isMobile && !isTablet && (
                  <Box className="cornerCell">
                    <Typography variant="h6" className="price">
                      Compare Plans
                    </Typography>
                    <Typography variant="subtitle2" className="subtext">
                      Choose a plan that works best for your business
                    </Typography>
                  </Box>
                )}
              </TableCell>
              {updatedPlanList.slice(0, -1).map((plan, index) => {
                return (
                  <TableCell key={index}>
                    {isMobile || isTablet ? (
                      <Box className="columnHeadMobile">
                        {plan.title === "Business" ? (
                          <img src={BlackStarIcon} alt="star icon" />
                        ) : (
                          <Box style={{ height: "27px" }}></Box>
                        )}
                        <Chip
                          sx={{ fontSize: isSmallMobile ? "9px" : "" }}
                          label={plan.title}
                          className={`cardChip ${plan.title}`}
                        />
                      </Box>
                    ) : (
                      <Box className="columnHead">
                        <Typography variant="body2" className="cardHeading">
                          {plan.title}
                        </Typography>
                        <Box className="cardPrice">
                          <Typography variant="h4" className="price">
                            ${plan.price}
                          </Typography>
                          <Typography variant="subtitle2" className="pricePer">
                            /month
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className="tableBodyMobile">
            {rows.map((plan, planIndex) => (
              <TableRow key={planIndex}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontWeight: isMobile || isTablet ? 600 : 500,
                    borderBottom:
                      planIndex === rows.length - 1 && "unset !important",
                  }}
                >
                  {(isMobile || isTablet) && planIndex === 0
                    ? "Profile views"
                    : plan.name}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom:
                      planIndex === rows.length - 1 && "unset !important",
                  }}
                >
                  {iconDisplay(plan.free)}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom:
                      planIndex === rows.length - 1 && "unset !important",
                  }}
                >
                  {iconDisplay(plan.business)}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom:
                      planIndex === rows.length - 1 && "unset !important",
                  }}
                >
                  {iconDisplay(plan.premium)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PlanTable;
