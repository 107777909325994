export const GET_ALL_RECOMMENDATIONS = "GET_ALL_RECOMMENDATIONS";
export const GET_ALL_RECOMMENDATIONS_SUCCESS =
  "GET_ALL_RECOMMENDATIONS_SUCCESS";
export const GET_ALL_RECOMMENDARTIONS_ERROR = "GET_ALL_RECOMMENDARTIONS_ERROR";
export const GET_ALL_FEEDS = "GET_ALL_FEEDS";
export const GET_ALL_FEEDS_ERROR = "GET_ALL_FEEDS_ERROR";
export const GET_FEED_ID_LOADING = "GET_FEED_ID_LOADING";
export const GET_FEED_ID = "GET_FEED_ID";
export const GET_FEED_ID_ERROR = "GET_FEED_ID_ERROR";
export const GET_ALL_PERSONAS = "GET_ALL_PERSONAS";
export const GET_ALL_PERSONAS_ERROR = "GET_ALL_PERSONAS_ERROR";
export const UPDATE_PERSONAS = "UPDATE_PERSONAS";
export const UPDATE_PERSONAS_ERROR = "UPDATE_PERSONAS_ERROR";
export const GET_USER_RATING = "GET_USER_RATING";
export const GET_USER_RATING_ERROR = "GET_USER_RATING_ERROR";

export const CREATE_RATE_SUCCESS_DASHBOARD = "CREATE_RATE_SUCCESS_DASHBOARD";
export const CREATE_RATE_ERROR_DASHBOARD = "CREATE_RATE_ERROR_DASHBOARD ";
export const GET_DATA = "GET_DATA";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const RESET_FILTERS = "RESET_FILTERS";
