import axios from "axios";

import {
  GET_ALL_RECOMMENDATIONS,
  GET_ALL_RECOMMENDARTIONS_ERROR,
  GET_FEED_ID_LOADING,
  GET_ALL_FEEDS,
  GET_ALL_FEEDS_ERROR,
  GET_FEED_ID,
  GET_FEED_ID_ERROR,
  GET_ALL_PERSONAS,
  GET_ALL_PERSONAS_ERROR,
  UPDATE_PERSONAS,
  UPDATE_PERSONAS_ERROR,
  GET_USER_RATING,
  GET_USER_RATING_ERROR,
  CREATE_RATE_SUCCESS_DASHBOARD,
  CREATE_RATE_ERROR_DASHBOARD,
  GET_DATA,
  SET_LOADING,
  SET_ERROR,
  RESET_FILTERS,
} from "../constant/dashboard";
import { createAction } from "redux-actions";
export const createRatingDashboard =
  (token, ratingData, userId) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/admin/rating_review/customer`,
        ratingData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(getUserRatings(userId, token));

      dispatch({ type: CREATE_RATE_SUCCESS_DASHBOARD, payload: data });
    } catch (error) {
      dispatch({ type: CREATE_RATE_ERROR_DASHBOARD, payload: error });
    }
  };
//getuserratewithid
export const getUserRatings = (userId, token) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/admin/rating_review/customer?customerId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: GET_USER_RATING, payload: data });
  } catch (error) {
    dispatch({ type: GET_USER_RATING_ERROR, payload: error });
  }
};

export const getAllRecommendations = (token, email) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/admin/recommendations?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: GET_ALL_RECOMMENDATIONS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_RECOMMENDARTIONS_ERROR, payload: error });
  }
};

export const getAllFeeds = (token) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/customers`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
    dispatch({ type: GET_ALL_FEEDS, payload: data.message });
  } catch (error) {
    dispatch({ type: GET_ALL_FEEDS_ERROR, payload: error });
  }
};
export const getFilterInfo =
  (
    token,
    selectedBrands,
    selectedPersonas,
    selectedPower,
    selectedLocation,
    postalCode,
    searchQuery,
    lastName,
    firstName
  ) =>
  async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });

    try {
      const email = localStorage.getItem("email");
      const databillityId = localStorage.getItem("tenantID");

      let searchName = null;
      let searchPhone = null;
      let searchLastName = null;
      let searchFirstName = null;

      if (/^\d+$/.test(searchQuery)) {
        searchPhone = searchQuery;
      } else {
        searchName = searchQuery;
      }

      const params = new URLSearchParams();
      if (selectedBrands) params.append("brand", selectedBrands);
      if (selectedPersonas) params.append("secondaryPersona", selectedPersonas);
      if (selectedPower) params.append("buying_power", selectedPower);
      if (selectedLocation) params.append("location_city", selectedLocation);
      if (postalCode) params.append("postalCode", postalCode);
      if (email) params.append("email", email);
      if (databillityId) params.append("databillityId", databillityId);
      if (searchName) params.append("fullName", searchName);
      if (searchPhone) params.append("phoneNumber", searchPhone);
      if (searchFirstName) params.append("firstName", searchFirstName);
      if (searchLastName) params.append("firstName", searchLastName);
      const apiUrl = `${
        process.env.REACT_APP_CUSTOMERS_API_BASE_URL
      }/filter-info?${params.toString()}`;
      const { data } = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      dispatch({ type: GET_DATA, payload: data?.data });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: error.response?.data?.message || error.message,
      });
    } finally {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

export const resetFilters = createAction(RESET_FILTERS);

export const getFeedId = (id, token) => async (dispatch) => {
  try {
    await dispatch({ type: GET_FEED_ID_LOADING });
    //const config = await getAxiosConfig();
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/dashboard?customerId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const response2 = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/admin/rating_review/customer?customerId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: GET_FEED_ID, payload: data.message, rating: response2 });
  } catch (error) {
    dispatch({ type: GET_FEED_ID_ERROR, payload: error });
  }
};

export const getAllPersonas = (token) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/personas/list-all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: GET_ALL_PERSONAS, payload: data.message });
  } catch (error) {
    dispatch({ type: GET_ALL_PERSONAS_ERROR, payload: error });
  }
};

/**
 * TODO: Need to refactor, X-DBY-Key will not work anymore on /personas/secondary/change
 */
export const updatePersonas =
  (token, personaType, personaId, customerId, databillityId) =>
  async (dispatch) => {
    try {
      let url = "";
      if (personaType === "primary") {
        url = `${process.env.REACT_APP_BASE_API_URL}/api/personas/primary/change`;
      } else {
        url = `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/personas/secondary/change`;
      }
      const response = await axios.patch(
        url,
        {
          customerId: customerId,
          personaId: personaId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({ type: UPDATE_PERSONAS, payload: response });
    } catch (error) {
      dispatch({ type: UPDATE_PERSONAS_ERROR, payload: error });
    }
  };
