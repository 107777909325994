import { combineReducers } from 'redux'
import auth from './auth.js'
import coPilot from './co-pilot.js'
import notifications from './notification.js'
import connections from './connection.js'
import checkIn from './check-in.js'
import optOut from './opt-out.js'
import insights from './insights.js'
import onboarding from './onboarding.js'
import dashboard from './dashboard.js';
import settings from './settings.js'
import apikeys from './apikeys.js'
import subscriptionManagement from './subscriptionManagement.js'
import modals from './modals.js'
import payment from './payment.js'
import subscription from './subscription.js'


export default combineReducers({
    notifications,
    auth,
    coPilot,
    connections,
    checkIn,
    optOut,
    insights,
    onboarding,
    dashboard,
    settings,
    apikeys,
    subscriptionManagement,
    modals,
    payment,
    subscription,

})
