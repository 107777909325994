import axios from "axios";
import {
  GET_CONNECTIONS_REQUEST,
  GET_CONNECTIONS_REQUEST_FAIL,
  GET_CONNECTIONS_REQUEST_SUCCESS,
  SHOW_LAUNCH_BUTTON,
} from "../constant/connections";

export const getConnectionData = (token) => async (dispatch) => {
  try {
    const url = window.location.href;

    const databillityId = localStorage.getItem("tenantID");

    const parsedUrl = new URL(url);    
    const baseUrl = `${parsedUrl.origin}${parsedUrl.pathname}`;
    window.history.pushState({}, null, baseUrl);

    const params = parsedUrl.searchParams;
    const code = params.get("code");
    const state = params.get("state");
    const realmId = params.get("realmId");

    if (!code || !state || !realmId) {
      console.warn(
        "Missing required query parameters. Ensure 'code', 'state', and 'realmId' are present."
      );
      return;
    }

    try {
      axios
        .post(
          `https://gunq0cc1kc.execute-api.us-east-1.amazonaws.com/staging/quickbooks/connection`,
          { code, state, realmId, databillityId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response);
          if (response.data.code) {
            console.log("Your QBO Account Connected Successfully.");
            console.log(response.data);
          } else {
            console.log("Something went wrong.Please try again.");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (err) {
      console.error(err);
    }

    dispatch({ type: GET_CONNECTIONS_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_CONNECTIONS_API_URL}/dev/connections`
    );
    dispatch({ type: GET_CONNECTIONS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_CONNECTIONS_REQUEST_FAIL, payload: error });
  }
};
export const showLaunchButton = (isVisible) => (dispatch) => {
  dispatch({
    type: SHOW_LAUNCH_BUTTON,
    payload: isVisible,
  });
};
