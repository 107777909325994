import { React, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { canadianProvinces, usStates } from './province';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  FormHelperText
} from "@mui/material";
import {
  modalStyle,
  boolList,
  entityTypeList,
  countryList,
  industryList,
  numberOfLocationsList,
} from "../../../pages/Onboarding/constants.js";
import {
  scrollToTop,
  cleanPhoneNumber,
  formatPhoneNumber,
} from "../../../utils/utilities.js";
import Images from "../../../assets/img/ImgConstants.jsx";
import CancelModal from "../CancelModal/index.jsx";
import InformationIcon from "../../../assets/img/information-icon.svg";
import "./style.scss";
import { setCompanyInfo, skipInfo } from "../../../redux/actions/onboarding.js";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CompanyInformation = (props) => {
  const { setBackClicked } = props;
  const [storedCountry, setStoredCountry] = useState('');
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 760 });
  const isSmallMobile = useMediaQuery({ maxWidth: 375 });
  const [submitData, setSubmitData] =useState(false);
  const { onboarding } = useSelector(
    (state) => state.onboarding
  );

const  companyLegalName = localStorage.getItem('companyLegalName');
const doingBusinessName = localStorage.getItem('doingBusinessName');
   const country = localStorage.getItem("country");
   const [openStates, setOpenStates] = useState({
    public: false,
    entity:false,
    province:false,
    industry:false,
    location:false,
   
  });

  const handleOpen = (id) => setOpenStates((prev) => ({ ...prev, [id]: true }));
  const handleClose = (id) => setOpenStates((prev) => ({ ...prev, [id]: false }));
  const [provinceOpen, setProvinceOpen] = useState(false);

  const handleProvinceOpen = () => setProvinceOpen(true);
  const handleProvinceClose = () => setProvinceOpen(false);

  useEffect(() => {
    scrollToTop();
    
  }, []);
  useEffect(() => {
    const fetchCountry = () => {
      const country = localStorage.getItem("country") || '';
    
      setStoredCountry(country);
      formik.setFieldValue('country', country); // Set Formik value directly
    };
  
    fetchCountry();
  }, []);

  const [openCancel, setOpenCancel] = useState(false);
  const [openIndustryInfo, setOpenIndustryInfo] = useState(false);
  const [openLocationNumberModal, setOpenLocationNumberModal] = useState(false);

  const handleIndustryInfoClick = () => {
    setOpenIndustryInfo(true);
  };
  const skipCompanyinfo = () =>{
    dispatch(skipInfo());
 
  }
  const handleCloseIndustryInfo = () => setOpenIndustryInfo(false);
  const handleLocationNumberInfoClick = () => {
    setOpenLocationNumberModal(true);
  };
  const handleCloseLocationNumberModal = () =>
    setOpenLocationNumberModal(false);

  const initialValues = {
    baseCountry: "Yes",
    companyLegalName:companyLegalName,
      doingBusinessName:doingBusinessName,
    isPublicCompany: "",
    entityType: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateProvince: "",
    zipCode: "",
     country: country,
    industry: "",
    phone: "",
    email: "",
    website: "",
    EIN: "",
    additionalLocations: "",
    numberOfLocations: "",
    location1: "",
    location1AddressLine1: "",
    location1AddressLine2: "",
    location1City: "",
    location1StateProvince: "",
    location1ZipCode: "",
    location1Country: "",
    location1Phone: "",
    location1Email: "",
    location1Website: "",
  };

  const validationSchema = yup.object().shape({
    companyLegalName: yup
      .string()
      .required("Please enter your Company's Legal Name")
      .matches(/\S/, "Invalid Company's Legal Name"),
    doingBusinessName: yup
      .string()
      .required("Please enter your Company's Business Name")
      .matches(/\S/, "Invalid Company's Business Name"),
    isPublicCompany: yup.string().required("Please select an option"),
    entityType: yup.string().required("Please select an option"),
    addressLine1: yup
      .string()
      .required("Address line 1 is required")
      .matches(/\S/, "Invalid Address"),
    addressLine2: yup.string()
    // .required("Address line 2 is required")
    .matches(/\S/, "Invalid Address"),





    city: yup
      .string()
      .required("Please enter your City")
      .matches(/\S/, "Invalid City"),
      // stateProvince: yup
      // .string()
      // .required("Please enter your State/Province")
      // .matches(/\S/, "Invalid State/Province"),
      stateProvince: yup.string().when("country", {
        is: "Canada",
        then: () =>
          yup
            .string()
            .required("Please enter your Province")
            .matches(/\S/, "Invalid Province"),
        otherwise: () =>
          yup
            .string()
            .required("Please enter your State")
            .matches(/\S/, "Invalid State"),
      }),
    zipCode: yup.string().when("country", {
      is: "Canada",
      then: () =>
        yup
          .string()
          .required("Postal code is required")
          .matches(
            /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
            "Invalid postal code format"
          ),
      otherwise: () =>
        yup
          .string()
          .required("Zip code is required")
          .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid zip code format for US"),
    }),

    location1ZipCode: yup
      .string()
      .when(["location1Country", "additionalLocations"], {
        is: (location1Country, additionalLocations) =>
          additionalLocations === "Yes" && location1Country === "Canada",
        then: () =>
          yup
            .string()
            .required("Postal code is required")
            .matches(
              /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
              "Invalid postal code format for Canada"
            ),
        otherwise: () =>
          yup.string().when(["additionalLocations"], {
            is: (additionalLocations) => additionalLocations === "Yes",
            then: () =>
              yup
                .string()
                .required("Zip code is required ")
                .matches(
                  /^\d{5}(?:[-\s]\d{4})?$/,
                  "Invalid zip code format for the US"
                ),
            otherwise: () =>
              yup.string().notRequired("Postal Code/Zip Code is required"),
          }),
      }),
      
    country: yup.string().required("Please select your Country"),
    industry: yup.string().required("Please select an option"),
    phone: yup
      .string()
      .required("Please enter phone number")
      .min(14, "Phone must be at least 10 characters")
      .matches(/\S/, "Invalid phone"),
      email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    website: yup
      .string()
      .required("Website is required ")
      .matches(
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/.*)?$/,
        "Invalid website URL format"
      ),
  
    EIN: yup.string(),

    additionalLocations: yup.string(),
    numberOfLocations: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Please select an option"),
    }),
    location1: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Location name is required"),
    }),
    location1AddressLine1: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Address line 1 is required"),
    }),

    location1City: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("City is required"),
    }),
    location1StateProvince: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("State/Province is required"),
    }),


    location1Country: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Country is required"),
    }),
    location1Phone: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () =>
        yup
          .string()
          .required("Please enter phone number")
          .min(14, "Phone must be at least 10 characters")
          .matches(/\S/, "Invalid phone"),
    }),
    location1Email: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () =>
        yup
          .string()
          .email("Enter a valid email")
          .required("Email is required"),
    }),

    location1Website: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () =>
        yup
          .string()
          .required("Website is required ")
          .matches(
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/.*)?$/,
            "Invalid website URL format"
          ),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnMount: true,
    country: country,
    companyLegalName: companyLegalName ||  '',
    doingBusinessName: doingBusinessName || '',
  
    initialValues: {
      location1Country: '',
    },
    validate: (values) => {
      const errors = {};
     
      return errors;
    },
    onSubmit: (values) => {
      dispatch(
        setCompanyInfo({
          email: onboarding.account.email,
          company_based_location: "Yes",
          company_legal_name: companyLegalName || "",
          dba_name: doingBusinessName,
          public_company: values.isPublicCompany === "Yes",
          entity_type: values.entityType,
          address_line_1: values.addressLine1,
          address_line_2: values.addressLine2,
          city: values.city,
          state_province: values.stateProvince, // use formik value
          postal_code: values.zipCode,
          country: values.country, // Make sure country is included
          industry: values.industry,
          main_phone: cleanPhoneNumber(values.phone),
          main_email: values.email,
          website: values.website,
          ein: values.EIN,
          additional_locations: values.additionalLocations === "Yes",
          number_of_locations: parseInt(values.numberOfLocations, 10),
          location_name: values.location1,
          location_address_line_1: values.location1AddressLine1,
          location_address_line_2: values.location1AddressLine2,
          location_city: values.location1City,
          location_state_province: values.location1StateProvince,
          location_postal_code: values.location1ZipCode,
          location_country: values.location1Country,
          location_main_phone: cleanPhoneNumber(values.location1Phone),
          location_main_email: values.location1Email,
          location_website: values.location1Website,
        })
      );
    },
  });

  const handleSubmit = async () => {
    // Mark all fields as touched
    setSubmitData(true);

    // localStorage.setItem("country",formik.values.country)
    formik.setTouched({
      companyLegalName: true,
      doingBusinessName: true,
      isPublicCompany: true,
      entityType: true,
      addressLine1: true,
      addressLine2: true,
      city: true,
      stateProvince: true,
      zipCode: true,
      country: true,
      industry: true,
      phone: true,
      email: true,
      website: true,
      EIN: true,
      additionalLocations: true,
      numberOfLocations: true,
      location1: true,
      location1AddressLine1: true,
      location1AddressLine2: true,
      location1City: true,
      location1StateProvince: true,
      location1ZipCode: true,
      location1Country: true,
      location1Phone: true,
      location1Email: true,
      location1Website: true,
    });
  

    // Submit the form
    await formik.submitForm();
  };
  
  useEffect(() => {
    if (onboarding.companyInfo) {
      formik.setFieldValue("country", storedCountry);
 
    }
    if (onboarding.companyInfo) {
      formik.setFieldValue(
        "baseCountry",
        Object.keys(onboarding.companyInfo).length !== 0
          ? onboarding.companyInfo.company_based_location
            ? "Yes"
            : "No"
          : ""
      );
      formik.setFieldValue(
        "companyLegalName",
        companyLegalName
      );
      formik.setFieldValue(
        "doingBusinessName",
        doingBusinessName
      );
      formik.setFieldValue(
        "isPublicCompany",
        Object.keys(onboarding.companyInfo).length !== 0
          ? onboarding.companyInfo.public_company
            ? "Yes"
            : "No"
          : ""
      );
      formik.setFieldValue("entityType", onboarding.companyInfo.entity_type);
      formik.setFieldValue(
        "addressLine1",
        onboarding.companyInfo.address_line_1
      );
      formik.setFieldValue("addressLine2", onboarding.companyInfo.address_line_2);
      formik.setFieldValue("city", onboarding.companyInfo.city);
      formik.setFieldValue(
        "stateProvince",
        onboarding.companyInfo.state_province
      );
      formik.setFieldValue("zipCode", onboarding.companyInfo.postal_code);
      formik.setFieldValue("country", country);
      formik.setFieldValue("industry", onboarding.companyInfo.industry);
      formik.setFieldValue(
        "phone",
        formatPhoneNumber(onboarding.companyInfo.main_phone)
      );
      formik.setFieldValue("email", onboarding.companyInfo.main_email);
      formik.setFieldValue("website", onboarding.companyInfo.website);
      formik.setFieldValue("EIN", onboarding.companyInfo.ein);
      formik.setFieldValue(
        "additionalLocations",
        Object.keys(onboarding.companyInfo).length !== 0
          ? onboarding.companyInfo.additional_locations
            ? "Yes"
            : "No"
          : ""
      );
      formik.setFieldValue(
        "numberOfLocations",
        onboarding.companyInfo.number_of_locations
      );
      formik.setFieldValue("location1", onboarding.companyInfo.location_name);
      formik.setFieldValue(
        "location1AddressLine1",
        onboarding.companyInfo.location_address_line_1
      );
      formik.setFieldValue(
        "location1AddressLine2",
        onboarding.companyInfo.location_address_line_2
      );
      formik.setFieldValue(
        "location1City",
        onboarding.companyInfo.location_city
      );
      formik.setFieldValue(
        "location1StateProvince",
        onboarding.companyInfo.location_state_province
      );
      formik.setFieldValue(
        "location1ZipCode",
        onboarding.companyInfo.location_postal_code
      );
      formik.setFieldValue(
        "location1Country",
        onboarding.companyInfo.location_country
      );
      formik.setFieldValue(
        "location1Phone",
        formatPhoneNumber(onboarding.companyInfo.location_main_phone)
      );
      formik.setFieldValue(
        "location1Email",
        onboarding.companyInfo.location_main_email
      );
      formik.setFieldValue(
        "location1Website",
        onboarding.companyInfo.location_website
      );
    }
  }, [onboarding]);

  const handleCancelButtonClick = () => {
    setBackClicked(true);
    setOpenCancel(true);
  };
  const RotatingArrowIcon = ({ open }) => (
    <ArrowDropDownIcon
      style={{
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)', 
        transition: 'transform 0.3s ease', 
        marginRight: "4px"
      }}
    />
  );
  
  return (
    <Box className="infoForm">
      <Typography
        variant="h6"
        className="heading"
        style={{ fontSize: isMobile ? "16px" : "20px",
          textAlign: "left",
          marginBottom: isMobile ? "3px" : "10px",}}
      >
        Company Information
      </Typography>
      <Typography variant="body1" className="subHeading"  style={{
          fontSize: isMobile ? "12px" : "16px",
          marginBottom: isMobile ? "40px" : "60px",
        }}>
        Please provide all the information below
      </Typography>


    
      <FormikProvider value={formik}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
  <Box className="formItem">
  <FormControl error={false}> 
    <Typography variant="body1" className="formHead mobileFieldLegal" style={{marginBottom:'10px'}}>
      Company legal name
    </Typography>
    <TextField
  id="companyLegalName"
  disabled
  sx={{
    // Styling the input text when active
    input: {
      color: "#4A4C4D", // Input text color when enabled
      fontSize: "16px",
      fontWeight: "500",
    },
    // Styling the input text when disabled
    "& .Mui-disabled": {
      "-webkit-text-fill-color": "#4A4C4D !important",
      color: "#4A4C4D", // Color for disabled input
    },
  }}
  name="companyLegalName"
  value={formik.values.companyLegalName}
  onChange={formik.handleChange}
  onBlur={formik.handleBlur}
/>
     </FormControl> 
  </Box>
</Grid>
          <Grid item xs={6}>
            <Box className="formItem">
            <FormControl error={false}> 
              <Typography variant="body1" className="formHead" style={{marginBottom:'10px'}}>
                Doing business as name
              </Typography>
              <TextField 
                id="doingBusinessName"
                placeholder="ABC"
                variant="outlined"
                disabled
                sx={{
                  // Styling the input text when active
                  input: {
                    color: "#4A4C4D", // Input text color when enabled
                    fontSize: "16px",
                    fontWeight: "500",
                  },
                  // Styling the input text when disabled
                  "& .Mui-disabled": {
                    "-webkit-text-fill-color": "#4A4C4D !important",
                    color: "#4A4C4D", // Color for disabled input
                  },
                }}
                name="doingBusinessName"
                value={formik.values.doingBusinessName}
                onChange={formik.handleChange}
                
              />
               </FormControl> 
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Are you a public company?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.isPublicCompany ? "" : "Select"}
                </InputLabel>
                <Select
                open={openStates.public}
                onOpen={() => handleOpen('public')}
                onClose={() => handleClose('public')}
                IconComponent={(props) => <RotatingArrowIcon open={openStates.public} {...props} />}
                  id="isPublicCompany"
                  sx={{
                    // Styling the selected text in the Select field
                    "& .MuiSelect-select": {
                      color: "#4A4C4D", // Selected text color
                      fontSize: "16px", // Adjusting the font size
                      fontWeight: "500", // Font weight
                    },
                    // Styling the dropdown menu items
                    "& .MuiMenuItem-root": {
                      color: "#4A4C4D", // Menu item text color
                      fontSize: "16px",
                      fontWeight: "500",
                    },
                    // Styling the dropdown icon (the arrow)
                    "& .MuiSvgIcon-root": {
                      color: "#4A4C4D", // Icon color
                    }
                  }}
                  name="isPublicCompany"
                  value={formik.values.isPublicCompany}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.isPublicCompany &&
                      formik.errors.isPublicCompany
                  )}
                >
                  {boolList.map((isPublic, index) => (
                    <MenuItem
                      key={index}
                      value={isPublic}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {isPublic}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
                {formik.touched.isPublicCompany &&
                  formik.errors.isPublicCompany}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} style={{paddingTop:isMobile?"24px":""}}> 
            <Box className="formItem" style={{marginBottom:isMobile?"0px":""}}>
              <Typography
                variant="body1"
                className="formHead mobileFieldEntity" 
              >
                Entity type
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.entityType ? "" : "Select"}
                </InputLabel>
                <Select
                open={openStates.entity}
                onOpen={() => handleOpen('entity')}
                onClose={() => handleClose('entity')}
                IconComponent={(props) => <RotatingArrowIcon open={openStates.entity} {...props} />}
                  id="entityType"
                  sx={{
                    // Styling the selected text in the Select field
                    "& .MuiSelect-select": {
                      color: "#4A4C4D", // Selected text color
                      fontSize: "16px", // Adjusting the font size
                      fontWeight: "500", // Font weight
                    },
                    // Styling the dropdown menu items
                    "& .MuiMenuItem-root": {
                      color: "#4A4C4D", // Menu item text color
                      fontSize: "16px",
                      fontWeight: "500",
                    },
                    // Styling the dropdown icon (the arrow)
                    "& .MuiSvgIcon-root": {
                      color: "#4A4C4D", // Icon color
                    }
                  }}
                  name="entityType"
                  value={formik.values.entityType}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.isPublicCompany &&
                      formik.errors.isPublicCompany
                  )}
                >
                  {entityTypeList.map((entity, index) => (
                    <MenuItem
                      key={index}
                      value={entity}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {entity}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
                {formik.touched.isPublicCompany &&
                  formik.errors.isPublicCompany}
              </Typography>
            </Box>
          </Grid>
        
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead" style={{marginTop:'10px'}} >
                Company HQ located Address
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} style={{paddingTop:'5px'}}>
          <Box className="formItem">
  <TextField
    id="addressLine1"
    placeholder="Address Line 1"
    variant="outlined"
    name="addressLine1"
    sx={{
      // Styling the input text
      input: {
        color: "#4A4C4D", // Input text color
        fontSize: "16px", // Adjusting the font size
        fontWeight: "500",
        WebkitTextFillColor: "#4A4C4D", 
      },
      "&::placeholder":{
        color: "#C8C8CE", 
        fontWeight: "bold",
      },
    }}
    value={formik.values && formik.values.addressLine1}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.addressLine1 && submitData)}
    helperText={submitData ? formik.errors.addressLine1 : ""}
  />
</Box>


          </Grid>
          <Grid item xs={6} style={{paddingTop:'5px'}}>
            <Box className="formItem">
              <TextField
                id="addressLine2"
                placeholder="Address Line 2"
                variant="outlined"
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D", 
                  },
                  "&::placeholder":{
                    color: "#C8C8CE", 
                    fontWeight: "bold",
                  },
                }}
                name="addressLine2"
                value={formik.values?.addressLine2 || ""}
                onChange={formik.handleChange}
                // error={Boolean(formik.errors.addressLine2 && submitData)}
                // helperText={submitData ? formik.errors.addressLine2 : ""}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <TextField
                id="city"
                placeholder="City"
                variant="outlined"
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D", 
                  },
                  "&::placeholder":{
                    color: "#C8C8CE", 
                    fontWeight: "bold",
                  },
                }}
                name="city"
                value={formik.values && formik.values.city}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.city && submitData)}
                helperText={submitData? formik.errors.city:""} 
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
          <Box className="formItem">
      <FormControl error={false}> {/* No formik errors here as it's read-only */}
        <TextField
           value={formik.values && formik.values.country} // Value to be displayed, default to "Canada"
          displayEmpty
          sx={{
            // Styling the input text
            input: {
              color: "#4A4C4D", // Input text color
              fontSize: "16px", // Adjusting the font size
              fontWeight: "500",
              WebkitTextFillColor: "#4A4C4D !important", 
            },
            "&::placeholder":{
              color: "#C8C8CE", 
              fontWeight: "bold",
            },
          }}
          inputProps={{ 'aria-label': 'Select Country' }}
          disabled // Make the Select read-only
        />
          {/* Displaying the selected value only */}

      </FormControl>
    </Box>
    </Grid>
    <Grid item xs={6}>
  <Box className="formItem">
    <TextField
      id="zipCode"
      placeholder={formik.values.country === "Canada" ? "Postal Code" : "Zip Code"}
      variant="outlined"
      name="zipCode"
      sx={{
        input: {
          color: "#4A4C4D",
          fontSize: "16px",
          fontWeight: "500",
          WebkitTextFillColor: "#4A4C4D", 
        },
        "&::placeholder":{
          color: "#C8C8CE", 
          fontWeight: "bold",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#C8C8CE",
          fontWeight: "bold",
        },
      }}
      value={formik.values.zipCode}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.zipCode && submitData)}
  helperText={submitData? formik.errors.zipCode:""} 
    />
  </Box>
</Grid>
          <Grid item xs={6}>
          <Box className="formItem">
          <FormControl fullWidth variant="outlined" error={Boolean(formik.errors.stateProvince && submitData)}>
  <InputLabel shrink={formik.values.stateProvince ? true : false}>
    {formik.values.stateProvince ? "" : country === "Canada" ? "Province" : "State"}
  </InputLabel>
  <Select
    sx={{
      // Styling the selected text in the Select field
      "& .MuiSelect-select": {
        color: "#4A4C4D", // Selected text color
        fontSize: "16px", // Adjusting the font size
        fontWeight: "500", // Font weight
      },
      // Styling the dropdown menu items
      "& .MuiMenuItem-root": {
        color: "#4A4C4D", // Menu item text color
        fontSize: "16px",
        fontWeight: "500",
      },
      // Styling the dropdown icon (the arrow)
      "& .MuiSvgIcon-root": {
        color: "#4A4C4D", // Icon color
      }
    }}
    open={openStates.province}
    onOpen={() => handleOpen('province')}
    onClose={() => handleClose('province')}
    IconComponent={(props) => <RotatingArrowIcon open={openStates.province} {...props} />}
    labelId="stateProvince-label"
    id="stateProvince"
    name="stateProvince"
    value={formik.values.stateProvince || ""}
    onChange={formik.handleChange}
    displayEmpty
    inputProps={{ 'aria-label': `Select ${country === "Canada" ? "Province" : "State"}` }}
  >
    {/* Dynamically populate based on country */}
    {country === "Canada" && canadianProvinces.map((province) => (
      <MenuItem key={province.code} value={province.code}>
        {province.name}
      </MenuItem>
    ))}
    {country === "United States" && usStates.map((state) => (
      <MenuItem key={state.code} value={state.code}>
        {state.name}
      </MenuItem>
    ))}
  </Select>
  {submitData && formik.errors.stateProvince && (
    <Typography variant="caption" color="error" style={{marginTop:"10px"}}>
      {formik.errors.stateProvince}
    </Typography>

  )}
  
</FormControl>
    </Box>
      </Grid>
      
          <Grid item xs={12}>
            <Box className="formItem">
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2 }}
                onClick={handleIndustryInfoClick}
              >
                <Typography variant="body1" className="formHead">
                  What industry does your company belong to?
                </Typography>
                <img
                  src={InformationIcon}
                  alt="Information"
                  style={{ height:isMobile?"15px": "17px" }}
                />
              </Box>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.industry ? "" : "Select"}
                </InputLabel>
                <Select
                open={openStates.industry}
                onOpen={() => handleOpen('industry')}
                onClose={() => handleClose('industry')}
                IconComponent={(props) => <RotatingArrowIcon open={openStates.industry} {...props} />}
                  id="industry"
                  name="industry"
                  value={formik.values.industry}
                  sx={{
                    // Styling the input text
                    "& .MuiSelect-select": {
                      color: "#4A4C4D", 
                      fontSize: "16px", // Selected text color
                      fontWeight: "500", // Font weight
                    },
                    // Styling the dropdown menu items
                    "& .MuiMenuItem-root": {
                      color: "#4A4C4D", // Menu item text color
                      fontSize: "16px",
                      "& .MuiSvgIcon-root": {
                        color: "#4A4C4D", // Icon color
                      },
                    }, // Closing for "& .MuiMenuItem-root"
                  }} 
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.industry && submitData)}
                  helperText={submitData ? formik.errors.industry : ""}
                >
                  {industryList.map((industryType, index) => (
                    <MenuItem
                      key={index}
                      value={industryType}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {industryType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
      {submitData && formik.errors.industry}
    </Typography>
            </Box>
            <Modal open={openIndustryInfo} onClose={handleCloseIndustryInfo}>
              <Box
                sx={{
                  ...modalStyle,
                  width: isMobile ? "80%" : isTablet ? "40%" : "30%",
                }}
              >
                <Button
                  onClick={handleCloseIndustryInfo}
                  sx={{ left: "50%", mb: 2 }}
                >
                  {Images.CLOSE_MODAL}
                </Button>
                <Typography variant="subtitle2" sx={{ mb: 4 }}>
                  DataBillity is suited for a specific type of business and
                  activities. If your business/activity is listed then
                  DataBillity is for you!
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 4 }}>
                  If your business/activity type is not listed, feel free to
                  reach out to our team to learn how DataBillity might be able
                  to provide value for your sales and marketing initiatives.
                </Typography>
              </Box>
            </Modal>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead mobileFieldPhone">
                Main Phone
              </Typography>
              <TextField
                id="phone"
                placeholder="999-999-9999"
                variant="outlined"
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D", 
                  },
                  "&::placeholder":{
                    color: "#C8C8CE", 
                    fontWeight: "bold",
                  },
                }}
                name="phone"
                value={formik.values && formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue(
                    "phone",
                    formatPhoneNumber(e.target.value)
                  )
                }
                error={Boolean(formik.touched.phone && formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
  <Box className="formItem">
    <Typography variant="body1" className="formHead">
      Main email address
    </Typography>
    <TextField
      id="email"
      placeholder="email@email.com"
      variant="outlined"
      name="email"
      sx={{
        input: {
          color: "#4A4C4D",
          fontSize: "16px",
          fontWeight: "500",
          WebkitTextFillColor: "#4A4C4D", 
        },
        "&::placeholder":{
          color: "#C8C8CE", 
          fontWeight: "bold",
        },
      }}
      value={formik.values.email}
      onChange={formik.handleChange}
      error={Boolean(submitData && formik.errors.email )}

      helperText={
       submitData? formik.errors.email:""
      }
    />
  </Box>
</Grid>
          <Grid item xs={6}>
  <Box className="formItem">
    <Typography variant="body1" className="formHead mobileFieldWebsite">
      Website
    </Typography>
    <TextField
      id="website"
      placeholder="website.com"
      variant="outlined"
      sx={{
        // Styling the input text
        input: {
          color: "#4A4C4D", // Input text color
          fontSize: "16px", // Adjusting the font size
          fontWeight: "500",
          WebkitTextFillColor: "#4A4C4D", 
        },
        "&::placeholder":{
          color: "#C8C8CE", 
          fontWeight: "bold",
        },
      }}
      name="website"
      value={formik.values && formik.values.website}
      onChange={formik.handleChange}
      error={Boolean(submitData && formik.errors.website )}
      helperText={
       submitData? formik.errors.website:""
      }
    />
  </Box>
</Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Employer Identification Number (EIN)
              </Typography>
              <TextField
                id="EIN"
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D", 
                  },
                  "&::placeholder":{
                    color: "#C8C8CE", 
                    fontWeight: "bold",
                  },
                }}
                placeholder="Optional"
                variant="outlined"
                name="EIN"
                value={formik.values && formik.values.EIN}
                onChange={formik.handleChange}
                
              />
              
            </Box>
          </Grid>
          <Grid item xs={formik.values?.additionalLocations === "Yes" ? 6 : 12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Does your company have additional locations?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.additionalLocations ? "" : "Select"}
                </InputLabel>
                <Select
                open={openStates.location}
                onOpen={() => handleOpen('location')}
                onClose={() => handleClose('location')}
                IconComponent={(props) => <RotatingArrowIcon open={openStates.location} {...props} />}
                  id="additionalLocations"
                  name="additionalLocations"
                  value={formik.values.additionalLocations}
                  sx={{
                    // Styling the selected text in the Select field
                    "& .MuiSelect-select": {
                      color: "#4A4C4D", // Selected text color
                      fontSize: "16px", // Adjusting the font size
                      fontWeight: "500", // Font weight
                    },
                    // Styling the dropdown menu items
                    "& .MuiMenuItem-root": {
                      color: "#4A4C4D", // Menu item text color
                      fontSize: "16px",
                      fontWeight: "500",
                    },
                    // Styling the dropdown icon (the arrow)
                    "& .MuiSvgIcon-root": {
                      color: "#4A4C4D", // Icon color
                    }
                  }}
                  onChange={formik.handleChange}
                  // error={Boolean(
                  //   formik.touched.additionalLocations &&
                  //     formik.errors.additionalLocations
                  // )}
                >
                  {boolList.map((location, index) => (
                    <MenuItem
                      key={index}
                      value={location}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Typography variant="caption" color="error">
                {formik.touched.additionalLocations &&
                  formik.errors.additionalLocations}
              </Typography> */}
            </Box>
          </Grid>
          {formik.values?.additionalLocations === "Yes" ? (
            <>
              <Grid item xs={6}>
                <Box className="formItem">
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                    onClick={handleLocationNumberInfoClick}
                    
                  >
                    <Typography
                      variant="body1"
                      className="formHead mobileFieldLocations"
                    >
                      How many locations?
                      <img
                        src={InformationIcon}
                        alt="Information"
                        style={{ marginLeft: "12px", height:isMobile?"14px": "17px" }}
                      />
                    </Typography>
                  </Box>
                  <FormControl>
                    <InputLabel shrink={false}>
                      {formik.values.numberOfLocations ? "" : "Select"}
                    </InputLabel>
                    <Select
                      id="numberOfLocations"
                      name="numberOfLocations"
                      onOpen={() => handleOpen('numberOfLocations')}
                      onClose={() => handleClose('numberOfLocations')}
                      IconComponent={(props) => (
                        <RotatingArrowIcon open={openStates.numberOfLocations} {...props} />
                      )}
                      sx={{
                        "& .MuiSelect-select": {
                          color: "#4A4C4D", 
                          fontSize: "16px", 
                          fontWeight: "500", 
                        },
                        "& .MuiMenuItem-root": {
                          color: "#4A4C4D", 
                          fontSize: "16px",
                          fontWeight: "500",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#4A4C4D", 
                        }
                      }}
                      value={formik.values.numberOfLocations}
                      onChange={formik.handleChange}
                      error={Boolean(submitData && formik.errors.numberOfLocations && formik.values.additionalLocations==="Yes")}
                    
                    >
                      {numberOfLocationsList.map((number, index) => (
                        <MenuItem
                          key={index}
                          value={number}
                          sx={{ color: "#6F7174", fontWeight: "medium" }}
                        >
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="caption" color="error">
      {submitData && formik.errors.numberOfLocations}
    </Typography>
                </Box>
                <Modal
                  open={openLocationNumberModal}
                  onClose={handleLocationNumberInfoClick}
                >
                  <Box
                    sx={{
                      ...modalStyle,
                      width: isMobile ? "80%" : isTablet ? "40%" : "30%",
                    }}
                  >
                    <Button
                      onClick={handleCloseLocationNumberModal}
                      sx={{ left: "50%", mb: 2 }}
                    >
                      {Images.CLOSE_MODAL}
                    </Button>
                    <Typography variant="subtitle2" sx={{ mb: 4 }}>
                      If you have more than 5 retail/event locations please
                      reach out to your Customer Success Manager or
                      email.tech@databillity.com to get help with a bulk upload.
                    </Typography>
                  </Box>
                </Modal>
              </Grid>
              <Grid item xs={12}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Location Name
                  </Typography>
                  <TextField
                    id="location1"
                    placeholder="Location 1"
                    variant="outlined"
                    sx={{
                      // Styling the input text
                      input: {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500",
                        WebkitTextFillColor: "#4A4C4D", 
                      },
                      "&::placeholder":{
                        color: "#C8C8CE", 
                        fontWeight: "bold",
                      },
                    }}
                    name="location1"
                    value={formik.values && formik.values.location1}
                    onChange={formik.handleChange}
                    error={Boolean(submitData && formik.errors.location1 && formik.values.additionalLocations==="Yes")}
                    helperText={
                     submitData? formik.errors.location1:""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Location Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <TextField
                    id="location1AddressLine1"
                    placeholder="Address Line 1"
                    sx={{
                      // Styling the input text
                      input: {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500",
                        WebkitTextFillColor: "#4A4C4D", 
                      },
                      "&::placeholder":{
                        color: "#C8C8CE", 
                        fontWeight: "bold",
                      },
                    }}
                    variant="outlined"
                    name="location1AddressLine1"
                    value={formik.values && formik.values.location1AddressLine1}
                    onChange={formik.handleChange}
                    error={Boolean(submitData && formik.errors.location1AddressLine1 && formik.values.additionalLocations==="Yes")}
                    helperText={
                     submitData? formik.errors.location1AddressLine1:""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <TextField
                  sx={{
                    // Styling the input text
                    input: {
                      color: "#4A4C4D", // Input text color
                      fontSize: "16px", // Adjusting the font size
                      fontWeight: "500",
                      WebkitTextFillColor: "#4A4C4D", 
                    },
                    "&::placeholder":{
                      color: "#C8C8CE", 
                      fontWeight: "bold",
                    },
                  }}
                    id="location1AddressLine2"
                    placeholder="Address Line 2"
                    variant="outlined"
                    name="location1AddressLine2"
                    value={formik.values && formik.values.location1AddressLine2}
                    onChange={formik.handleChange}
                    error={Boolean(submitData && formik.errors.location1AddressLine2 && formik.values.additionalLocations==="Yes")}
                    helperText={
                     submitData? formik.errors.location1AddressLine2:""
                    }
                 
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">

                  <TextField
                    id="location1City"
                    placeholder="City"
                    variant="outlined"
                    sx={{
                      // Styling the input text
                      input: {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500",
                        WebkitTextFillColor: "#4A4C4D", 
                      },
                      "&::placeholder":{
                        color: "#C8C8CE", 
                        fontWeight: "bold",
                      },
                    }}
                    name="location1City"
                    value={formik.values && formik.values.location1City}
                    onChange={formik.handleChange}
                    error={Boolean(submitData && formik.errors.location1City && formik.values.additionalLocations==="Yes")}
                    helperText={
                     submitData? formik.errors.location1City:""
                    }
                
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box className="formItem">
              <FormControl
  fullWidth
  variant="outlined"
>
  <InputLabel shrink={Boolean(formik.values.location1StateProvince)}>
    {formik.values.location1StateProvince
      ? ""
      : formik.values.location1Country === "Canada"
      ? "Province"
      : "State"}
  </InputLabel>
  <Select
    sx={{
      "& .MuiSelect-select": {
        color: "#4A4C4D",
        fontSize: "16px",
        fontWeight: "500",
      },
      "& .MuiMenuItem-root": {
        color: "#4A4C4D",
        fontSize: "16px",
        fontWeight: "500",
      },
      "& .MuiSvgIcon-root": {
        color: "#4A4C4D",
      },
    }}
    open={provinceOpen}
    onOpen={handleProvinceOpen}
    onClose={handleProvinceClose}
    labelId="location1StateProvince-label"
    id="location1StateProvince"
    name="location1StateProvince"
    value={formik.values.location1StateProvince || ""}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.location1StateProvince && submitData)}
    displayEmpty
    inputProps={{
      "aria-label": `Select ${
        formik.values.location1Country === "Canada" ? "Province" : "State"
      }`,
    }}
    IconComponent={(props) => (
      <RotatingArrowIcon open={provinceOpen} {...props} />
    )}
  >
    {formik.values.location1Country === "Canada"
      ? canadianProvinces.map((province) => (
          <MenuItem key={province.code} value={province.code}>
            {province.name}
          </MenuItem>
        ))
      : usStates.map((state) => (
          <MenuItem key={state.code} value={state.code}>
            {state.name}
          </MenuItem>
        ))}
  </Select>

<Typography variant="caption" color="error" style={{marginTop:"10px"}}>
  {formik.submitCount > 0 && formik.errors.location1StateProvince
    ? formik.values.location1Country === "Canada"
      ? "Province is required"
      : "State is required"
    : ""}
</Typography>

 
      
</FormControl>

</Box>
</Grid>
              <Grid item xs={6}>
  <Box className="formItem">
    <TextField
      id="location1ZipCode"
      placeholder={
        formik.values.location1Country
          ? formik.values.location1Country === "Canada"
            ? "Postal Code"
            : "Zip Code"
          : "Postal Code/Zip Code"
      }
      variant="outlined"
      name="location1ZipCode"
      sx={{
        input: {
          color: "#4A4C4D",
          fontSize: "16px",
          fontWeight: "500",
          WebkitTextFillColor: "#4A4C4D",
        },
        "&::placeholder": {
          color: "#C8C8CE",
          fontWeight: "bold",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#C8C8CE",
          fontWeight: "bold",
        },
      }}
      value={formik.values.location1ZipCode}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.location1ZipCode && submitData)}
      helperText={
        submitData ? (
          <FormHelperText
          style={{marginTop:"0px"}}
          >
            {formik.errors.location1ZipCode}
          </FormHelperText>
        ) : (
          ""
        )
      }
    />
  </Box>
</Grid>


              <Grid item xs={6}>
                <Box className="formItem">
                  <FormControl>
                    <InputLabel
                      shrink={formik.values.location1Country ? true : false}
                      sx={{
                        color: formik.values.location1Country
                          ? "#4A4C4D"
                          : "#C8C8CE",
                        fontWeight: formik.values.location1Country
                          ? "500"
                          : "bold",
                      }}
                    >
                      {formik.values.location1Country ? "" : "Country"}
                    </InputLabel>
                    <Select
                      id="location1Country"
                      name="location1Country"
                      value={formik.values.location1Country}
                      onChange={formik.handleChange}
                      onOpen={() => handleOpen("location1Country")}
                      onClose={() => handleClose("location1Country")}
                      IconComponent={(props) => (
                        <RotatingArrowIcon
                          open={openStates.location1Country}
                          {...props}
                        />
                      )}
                      sx={{
                        "& .MuiSelect-select": {
                          color: "#4A4C4D", // Selected text color
                          fontSize: "16px", // Adjusting the font size
                          fontWeight: "500", // Font weight
                        },
                        "& .MuiMenuItem-root": {
                          color: "#4A4C4D", // Menu item text color
                          fontSize: "16px",
                          fontWeight: "500",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#4A4C4D", // Icon color
                        },
                      }}
                      error={Boolean(
                        formik.errors.location1Country && submitData
                      )}
                    >
                      {countryList.map((selectedCountry, index) => (
                        <MenuItem
                          key={index}
                          value={selectedCountry}
                          sx={{ color: "#6F7174", fontWeight: "medium" }}
                        >
                          {selectedCountry}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

    <Typography variant="caption" color="error">
      {submitData && formik.errors.location1Country}
    </Typography>
      

  </Box>
</Grid>


              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography
                    variant="body1"
                    className="formHead mobileFieldPhone"
                  >
                    Main Phone
                  </Typography>
                  <TextField
                    id="location1Phone"
                    placeholder="999-999-9999"
                    variant="outlined"
                    sx={{
                      // Styling the input text
                      input: {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500",
                        WebkitTextFillColor: "#4A4C4D", 
                      },
                      "&::placeholder":{
                        color: "#C8C8CE", 
                        fontWeight: "bold",
                      },
                    }}
                    name="location1Phone"
                    value={formik.values && formik.values.location1Phone}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "location1Phone",
                        formatPhoneNumber(e.target.value)
                      )
                    }
                    error={Boolean(
                      formik.touched.location1Phone &&
                        formik.errors.location1Phone
                    )}
                    helperText={
                      formik.touched.location1Phone &&
                      formik.errors.location1Phone
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Main email address
                  </Typography>
                  <TextField
                    id="location1Email"
                    placeholder="email@email.com"
                    variant="outlined"
                    sx={{
                      // Styling the input text
                      input: {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500",
                        WebkitTextFillColor: "#4A4C4D", 
                      },
                      "&::placeholder":{
                        color: "#C8C8CE", 
                        fontWeight: "bold",
                      },
                    }}
                    name="location1Email"
                    value={formik.values.location1Email}
                    onChange={formik.handleChange}
                    error={Boolean(submitData && formik.errors.location1Email && formik.values.additionalLocations==="Yes")}
                    helperText={
                     submitData? formik.errors.location1Email:""
                    }
                  />
                </Box>
              </Grid>
              
              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Website
                  </Typography>
                  <TextField
                    id="location1Website"
                    placeholder="website.com"
                    variant="outlined"
                    sx={{
                      // Styling the input text
                      input: {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500",
                        WebkitTextFillColor: "#4A4C4D", 
                      },
                      "&::placeholder":{
                        color: "#C8C8CE", 
                        fontWeight: "bold",
                      },
                    }}
                    name="location1Website"
                    value={formik.values && formik.values.location1Website}
                    onChange={formik.handleChange}
                    error={Boolean(
                      submitData && formik.errors.location1Website
                    )}
                    helperText={
                      submitData ? formik.errors.location1Website : ""
                    }
                  />
                </Box>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
         
            <Box className="formButtons">
              <Button variant="outlined" onClick={handleCancelButtonClick}>
                Back
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Next
              </Button>
              <CancelModal open={openCancel} setOpen={setOpenCancel} />
            </Box>
            <div onClick={skipCompanyinfo} style={{justifyContent: "center",
    display: "flex",
    paddingTop: "50px",
    color: "#0050BC",
    fontWeight: "500",
    cursor:"pointer"}}> Skip this step</div>
          </Grid>
        </Grid>
      </FormikProvider>
   
    </Box>

  );
};

export default CompanyInformation;
