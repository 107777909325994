import { CLEAR_STORE } from "../constant/auth";
import {
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_ERROR,
  LOCATION_INFO_UPLOAD_CSV, SET_LOCATION_FORM_DATA, SUBMIT_LOCATION_FORM_DATA, SET_LOCATION_ERROR, SET_lOCATION_LOADING,
  GET_LOCATION_INFO_SUCCESS,
  GET_LOCATION_INFO_ERROR,
  DELETE_LOCATION_BY_ID,
  DELETE_LOCATION_BY_ID_ERROR,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INFO_ERROR,
  UPDATE_ACCOUNT_INFO_ORG,
  UPDATE_ACCOUNT_INFO_ORG_ERROR,
  INVITE_USER,
  INVITE_USER_ERROR,
  COUNT_USERS_SUCCESS,
  COUNT_USERS_ERROR,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_USERS_INFO_SUCCESS,
  GET_USERS_INFO_ERROR,
  SUBMIT_USER_INFO_SUCCESS,
  SUBMIT_USER_INFO_ERROR,
  DELETE_USER_BY_ID,
  DELETE_USER_BY_ID_ERROR,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_ERROR,
  POST_INFO_SUCCESS,
  POST_INFO_ERROR,
  GET_PARTICULAR_LOCATION,
  GET_PARTICULAR_LOCATION_ERROR
} from "../constant/settings";

const initialState = {
  accountInfo: {},
  locationInfo: {},
  locationInfoById: null,
  updateAccountData: false,
  updateUserData: false,
  errors: {},
  updateAccountOrgInfo: false,
  inviteSent: false,
  count: "",
  allUsersData: null,
  userInfo: null,
  submittedInfo: false,
  deletedUser: false,
  resend: false,
  formData: {
    location_Name: '',
    // main_phone: '',
    // main_email: '',
    // street_address: '',
    // suite_unit_apt: '',
    city: '',
    state_province: '',
    postal_code: '',
    country: 'US',
  },
  csvData: [],
  loading: false,
  error: '',
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STORE:
      return initialState;

    case GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfo: action.payload,
      };
    case GET_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        errors: {
          accountInfo: action.payload,
        },
      };
    case DELETE_LOCATION_BY_ID:
      return {
        ...state,
        // Filter out the deleted location from the list of locations
        locations: state.locations.filter(
          (location) => location.location_id !== action.payload.location_id
        ),
        deletedLocation: action.payload, // Store the deleted location's data
        loading: false,
        error: null,
      };

    case DELETE_LOCATION_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload, // Handle errors when deleting a location
      };
    case GET_LOCATION_INFO_SUCCESS:
      return {
        ...state,
        locationInfo: action.payload,
      };
    case GET_LOCATION_INFO_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          locationInfo: action.payload,
        },
      };
    case LOCATION_INFO_UPLOAD_CSV:
      return {
        ...state,
        csvData: action.payload,
      };

    case SET_LOCATION_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.name]: action.payload.value,
        },
      };

    case SUBMIT_LOCATION_FORM_DATA:
      return {
        ...state,
        formData: initialState.formData, // Reset form data after submission
        csvData: [], // Clear CSV data after submission
        error: '',
      };

    case SET_LOCATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case SET_lOCATION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case POST_INFO_SUCCESS:
      return {
        ...state,
        errors: {
          locationInfo: action.payload,
        },
      };
    case POST_INFO_ERROR:
      return {
        ...state,
        locationInfo: action.payload,
      };
    case INVITE_USER:
      return {
        ...state,
        inviteSent: true,
      };
    case INVITE_USER_ERROR:
      return {
        ...state,
        errors: {
          inviteSent: action.payload,
        },
      };

    case UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        updateAccountData: true,
      };
    case UPDATE_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        errors: {
          updateAccountData: action.payload,
        },
      };
    // case UPDATE_USER_INFO_LOC:
    //   return {
    //     ...state,
    //     updateUserData: true,
    //   };
    // case UPDATE_USER_INFO_LOC_ERROR:
    //   return {
    //     ...state,
    //     errors: {
    //       updateAcupdateUserData: action.payload,
    //     },
    //   };
    case UPDATE_ACCOUNT_INFO_ORG:
      return {
        ...state,
        updateAccountOrgInfo: true,
      };
    case UPDATE_ACCOUNT_INFO_ORG_ERROR:
      return {
        ...state,
        errors: {
          updateAccountOrgInfo: action.payload,
        },
      };
    case COUNT_USERS_SUCCESS:
      return {
        ...state,
        count: action.payload,
      };
    case COUNT_USERS_ERROR:
      return {
        ...state,
        errors: {
          count: action.payload,
        },
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsersData: action.payload,
      };
    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        errors: {
          allUsersData: action.payload,
        },
      };

    case GET_USERS_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
      };
    case GET_USERS_INFO_ERROR:
      return {
        ...state,
        errors: {
          userInfo: action.payload,
        },
      };

      case GET_PARTICULAR_LOCATION:
        return {
          ...state,
          locationInfoById: action.payload,
        };
      case GET_PARTICULAR_LOCATION_ERROR:
        return {
          ...state,
          errors: {
            locationInfoById: action.payload,
          },
        }
    case SUBMIT_USER_INFO_SUCCESS:
      return {
        ...state,
        submittedInfo: true,
      };
    case SUBMIT_USER_INFO_ERROR:
      return {
        ...state,
        errors: {
          submittedInfo: false,
        },
      };
    case DELETE_USER_BY_ID:
      return {
        ...state,
        deletedUser: true,
      };
    case DELETE_USER_BY_ID_ERROR:
      return {
        ...state,
        errors: {
          deletedUser: false,
        },
      };
    case RESEND_INVITE_SUCCESS:
      return {
        ...state,
        resend: true,
      };
    case RESEND_INVITE_ERROR:
      return {
        ...state,
        errors: {
          resend: false,
        },
      };
    default:
      return state;
  }
}
