import {
  Box,
  Button,
  Chip,
  Grid,
  Typography,
  Avatar,
  Paper,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { styled } from "@mui/material/styles";
import woman from "../../../assets/img/woman.png";
import Badge from "@mui/material/Badge";
import CustomerDetaiilTab from "../CustomerDetailView";
import { getOrAssignAvatar } from "../../../utils/avatar";
import { useDispatch, useSelector } from "react-redux";
import { getAllFeeds, getFeedId } from "../../../redux/actions/dashboard";

const FeedCardMobileView = () => {
  const [showComponent, setShowComponent] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { feedsData } = useSelector((state) => state.dashboard);
  const { token } = useSelector((state) => state.auth);
  const [allFeeds, setAllFeeds] = useState();
  const isMobile = useMediaQuery({ minWidth: 769 });
  const [open, setOpen] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 120,
    height: 120,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: "center",
  }));

  useEffect(() => {
    dispatch(getAllFeeds(token));
  }, []);
  const handleClick = () => {
    setShowComponent(!showComponent);
  };
  const compareDates = (a, b) => {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };
  function changeTimeFormat(bioTime) {
    let date = new Date(bioTime);

    let formatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    let formattedTime = formatter.format(date);
    return formattedTime;
  }

  useEffect(() => {
    if (feedsData) {
      setLoading(false);
      let data = feedsData.sort(compareDates);
      setAllFeeds(data);
    }
    // let currentTime= changeTimeFormat();
    //setRefreshDate(feedsDate + " " +currentTime);
  }, [feedsData]);
  function userDetailData(id) {
    if (id) {
      dispatch(getFeedId(id, token));
      setShowComponent(!showComponent);
    } else {
      setLoading(true);
    }
  }

  const statusIndicator = (checkinTime) => {
    var givenDate = new Date(checkinTime);
    var currentDate = new Date();
    var timeDifference = currentDate - givenDate;
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    if (hoursDifference <= 1) {
      return "green";
    } else if (hoursDifference > 1 && hoursDifference <= 2) {
      return "Yellow";
    } else {
      return "grey";
    }
  };
  const userData = [
    {
      name: "Mark Jones",
      rating: 4.56,
      color: "lightgreen",
    },
    {
      name: "Neera Patel",
      rating: 4.65,
      color: "lightgreen",
    },
    {
      name: "Naresh Patel",
      rating: 4.65,
      color: "lightgreen",
    },
  ];
  const skeletonCard = () => {
    const skeletonCount = 3;
    return (
      <React.Fragment>
        {Array(skeletonCount)
          .fill()
          .map((_, index) => (
            <React.Fragment key={index}>
              <div
                className={`container ${
                  isMobile ? (isBlurred ? "blur" : "") : ""
                }`}
              >
                <Typography variant="body2">
                  <Skeleton style={{ marginLeft: "6%" }} width="30%" />
                </Typography>
                <Box
                  className={`modal-overlay  ${!isMobile ? "segmentBio" : ""}`}
                  sx={{ p: 2 }}
                  style={{
                    width: "85%",
                    marginTop: "2%",
                    marginBottom: "7%",
                    marginLeft: "6%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Grid item style={{ display: "flex", direction: "row" }}>
                    <Skeleton
                      style={{ marginRight: "2%" }}
                      variant="circular"
                      width={60}
                      height={55}
                      className="skeletonCircular"
                    />
                    <Typography style={{ maxWidth: "50%" }} variant="body1">
                      <Skeleton variant="rectangular" height={15} width={60} />
                      <br />
                      <Skeleton variant="rectangular" height={15} width={60} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton
                      style={{ marginTop: "4%", marginLeft: "8%" }}
                      variant="rectangular"
                      width="90%"
                      height={80}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <Skeleton
                        variant="rectangular"
                        style={{
                          marginLeft: "16%",
                          borderRadius: "16px",
                          marginTop: "10px",
                        }}
                        height={20}
                        width="70%"
                      />
                    </Typography>
                  </Grid>
                </Box>
              </div>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {showComponent ? (
        <CustomerDetaiilTab />
      ) : loading ? (
        skeletonCard()
      ) : allFeeds?.length > 0 ? (
        allFeeds?.map((card, index) => (
          <div
            className={`container ${isMobile ? (isBlurred ? "blur" : "") : ""}`}
            id="feed"
            style={{ marginLeft: "29px", marginRight: "10px" }}
          >
            <React.Fragment>
              <Typography variant="body1" className="">
                {" "}
                {new Intl.DateTimeFormat().format(new Date(card.time))}
              </Typography>
              <Box
                className={`modal-overlay  ${!isMobile ? "segmentBio" : ""}`}
                style={{
                  marginBottom: "40px",
                  marginTop: "10%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    sm={2}
                    xs={3}
                    style={{ padding: "5%" }}
                  >
                    <Badge
                      overlap="circular"
                      badgeContent=" "
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      sx={{
                        "& .MuiBadge-badge": {
                          color: `${statusIndicator(card.time)}`,
                          backgroundColor: `${statusIndicator(card.time)}`,
                        },
                        marginLeft: {
                          xs: 0,
                          sm: "17%",
                        },
                      }}
                    >
                      <Avatar
                        alt="Bio Image"
                        src={getOrAssignAvatar(card.id)}
                        sx={{
                          width: 70,
                          height: 70,
                        }}
                      />
                    </Badge>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    sm={8}
                    xs={7}
                    style={{ padding: "5%" }}
                  >
                    <Typography variant="body1" className="blue bold-700">
                      <span>{card.name} </span>
                    </Typography>
                    <div>
                      {" "}
                      <Chip
                        label="Standard"
                        variant="filled"
                        size="small"
                        className="segmentBioGreenBadge badge"
                        style={{ fontSize: "small", marginTop: "2%" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2} onClick={handleClick}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => userDetailData(card.id)}
                    >
                      <path
                        d="M18.9005 9.04279C19.1374 9.30529 19.2685 9.64633 19.2685 9.99993C19.2685 10.3535 19.1374 10.6946 18.9005 10.9571C17.4005 12.5714 13.9862 15.7142 10.0005 15.7142C6.01476 15.7142 2.60048 12.5714 1.10048 10.9571C0.863563 10.6946 0.732422 10.3535 0.732422 9.99993C0.732422 9.64633 0.863563 9.30529 1.10048 9.04279C2.60048 7.4285 6.01476 4.28564 10.0005 4.28564C13.9862 4.28564 17.4005 7.4285 18.9005 9.04279Z"
                        stroke="#0063BC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0007 12.8571C11.5787 12.8571 12.8578 11.5779 12.8578 9.99996C12.8578 8.42201 11.5787 7.14282 10.0007 7.14282C8.42274 7.14282 7.14355 8.42201 7.14355 9.99996C7.14355 11.5779 8.42274 12.8571 10.0007 12.8571Z"
                        stroke="#0063BC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Grid>
                  <Grid itemsm={12} xs={12} style={{ padding: "5%" }}>
                    <DemoPaper
                      variant="outlined"
                      style={{
                        width: "80%",
                        margin: "5%",
                        textAlign: "left",
                        position: "relative",
                        height: "80px",
                      }}
                    >
                      <Typography variant="body1" className="blue bold-500">
                        New Customer check in
                      </Typography>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          color: "#02AFEF",
                        }}
                      >
                        {changeTimeFormat(card.time ? card.time : null)}
                      </div>
                    </DemoPaper>
                  </Grid>
                  <Grid item sm={12} xs={12} style={{ padding: "5%" }}>
                    <Button
                      className={`modal-overlay  ${
                        !isMobile ? "segmentBio" : ""
                      }`}
                      variant="contained"
                      style={{
                        marginLeft: "5%",
                        minWidth: "-webkit-fill-available",
                        fontSize: "80%",
                        borderRadius: "20px",
                        minHeight: "50px",
                      }}
                    >
                      Recommendations
                    </Button>
                  </Grid>
                  {/* <Grid item xs={1} style={{ paddingLeft: "0px" }}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="20"
                    cy="20"
                    r="19.5"
                    fill="white"
                    stroke="#0050BC"
                  />
                  <path
                    d="M12.5 18.5C11.675 18.5 11 19.175 11 20C11 20.825 11.675 21.5 12.5 21.5C13.325 21.5 14 20.825 14 20C14 19.175 13.325 18.5 12.5 18.5ZM27.5 18.5C26.675 18.5 26 19.175 26 20C26 20.825 26.675 21.5 27.5 21.5C28.325 21.5 29 20.825 29 20C29 19.175 28.325 18.5 27.5 18.5ZM20 18.5C19.175 18.5 18.5 19.175 18.5 20C18.5 20.825 19.175 21.5 20 21.5C20.825 21.5 21.5 20.825 21.5 20C21.5 19.175 20.825 18.5 20 18.5Z"
                    fill="#0050BC"
                  />
                </svg>
              </Grid> */}
                </Grid>
              </Box>
            </React.Fragment>
          </div>
        ))
      ) : (
        skeletonCard()
      )}
    </React.Fragment>
  );
};

export default FeedCardMobileView;
