import { Box, Button, Chip, Typography, Avatar, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Images from "../../../../assets/img/ImgConstants";
import "../../../../pages/Reports/style.scss";
import "./style.scss";

const BioCard = (props) => {
  const { name, image, rating, email, phone, isLoading } = props;
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const skeletonCard = () => {
    return (
      <Box  className={`segmentBio ${isMobile ? "modal-overlay" : ""}`}
      sx={{ padding: "12px 12px 0px 12px" }}>
        <Box>
          <Typography variant="h6">
            <Skeleton className="skeletonHeading" active />
          </Typography>
        </Box>
        <Skeleton
          variant="circular"
          className="skeletonAvatar avatarBio"
          active
        />
        <Box className="bioLowerSection skeletonLowerSection">
          <Skeleton className="skeletonRating" active />
          <Typography variant="body1" className="blue bold-700">
            <Skeleton className="skeletonHeading" active />
          </Typography>
          <Box className="bioChips">
            <Skeleton className="skeletonBadge" active />
            <Skeleton className="skeletonBadge" active />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {isLoading ? (
        skeletonCard()
      ) : (
        <Box  className={`segmentBio ${isMobile ? "modal-overlay" : ""}`}>
          <Box
            className={`bioUpperSection ${isMobile && "upperSectionMobile"}`}
            style={{padding:"15px", paddingLeft:"22px" ,display: "flex",
              alignItems: "center"}}
          >
            <Typography variant="h6" style={{fontWeight:"500", fontSize:"18px"}}>Profile</Typography>
            <Button>
              <MoreHorizOutlinedIcon
                sx={{ color: `${isMobile ? "white" : "#002857"}`, marginLeft: "auto", paddingRight:"6px", fontSize:"1.9rem" }}
              />
            </Button>
          </Box>
          <Box className="bioLowerSection">
            <Avatar alt="Bio Image Mobile" src={image} className="avatarBio" />
            <Box className="ratingBox">
            {Images.GOLDEN_STAR_ICON}
         
              <Typography variant="body1" className="darker-gray bold-700">
                {rating}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              className="blue bold-700"
              sx={{ pb: 1 , pt: 0.5, fontSize:"16px"}}
            >
              {name}
            </Typography>
            {isMobile && (
              <Box className="bioContact">
                {Images.EMAIL_ICON}
                <Typography
                  variant="subtitle2"
                  className="dark-gray"
                  sx={{ marginRight: "16px" }}
                >
                  {email}
                </Typography>
                {Images.PHONE_ICON}
                <Typography variant="subtitle2" className="dark-gray">
                  {phone}
                </Typography>
              </Box>
            )}
            <Box className="bioChips">
            <Chip
                label="Needs based"
                variant="filled"
                size="medium"
                className="segmentBioBlueBadge badge"
                style={{fontSize:"16px"}}
              />
              <Chip
                label="Research oriented"
                variant="filled"
                size="medium"
                className="segmentBioGreenBadge badge"
                style={{fontSize:"16px"}}
              />
              
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BioCard;
