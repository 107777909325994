export const GET_ACCOUNT_INFO_SUCCESS = "GET_ACCOUNT_INFO_SUCCESS";
export const GET_ACCOUNT_INFO_ERROR = "GET_ACCOUNT_INFO_ERROR";

export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";
export const UPDATE_ACCOUNT_INFO_ERROR = "UPDATE_ACCOUNT_INFO_ERROR";

export const UPDATE_ACCOUNT_INFO_ORG = "UPDATE_ACCOUNT_INFO_ORG";
export const UPDATE_ACCOUNT_INFO_ORG_ERROR = "UPDATE_ACCOUNT_INFO_ORG_ERROR";


export const LOCATION_INFO_UPLOAD_CSV = "LOCATION_INFO_UPLOAD_CSV";
export const  SET_LOCATION_FORM_DATA = " SET_LOCATION_FORM_DATA";
export const  SUBMIT_LOCATION_FORM_DATA = " SUBMIT_LOCATION_FORM_DATA";
export const  SET_LOCATION_ERROR = " SET_LOCATION_ERROR";
export const  SET_lOCATION_LOADING = " SET_lOCATION_LOADING";




export const DELETE_LOCATION_BY_ID = "DELETE_LOCATION_BY_ID";
export const DELETE_LOCATION_BY_ID_ERROR = "DELETE_LOCATION_BY_ID_ERROR";

export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";

export const COUNT_USERS_SUCCESS = "COUNT_USERS_SUCCESS";
export const COUNT_USERS_ERROR = "COUNT_USERS_ERROR";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

export const GET_USERS_INFO_SUCCESS = "GET_USERS_INFO_SUCCESS";
export const GET_USERS_INFO_ERROR = "GET_USERS_INFO_ERROR";

export const SUBMIT_USER_INFO_SUCCESS = "SUBMIT_USER_INFO_SUCCESS";
export const SUBMIT_USER_INFO_ERROR = "SUBMIT_USER_INFO_ERROR";

export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_USER_BY_ID_ERROR = "DELETE_USER_BY_ID_ERROR";

export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_ERROR = "RESEND_INVITE_ERROR";

export const GET_LOCATION_INFO_SUCCESS = "GET_LOCATION_INFO_SUCCESS";
export const GET_LOCATION_INFO_ERROR = "GET_LOCATION_INFO_ERROR";

export const POST_INFO_SUCCESS = "POST_INFO_SUCCESS";
export const POST_INFO_ERROR = "POST_INFO_ERROR";

export const GET_PARTICULAR_LOCATION = "GET_PARTICULAR_LOCATION";
export const GET_PARTICULAR_LOCATION_ERROR="GET_PARTICULAR_LOCATION_ERROR";
